import {
  Grid,
} from "@mui/material";

// import { useAuth0 } from "@auth0/auth0-react";

import logoImage from './ai-board-logo-blue.png'
import screenshotImage from './ai-board-screenshot.png'

import { ExploreOutlined, HandymanOutlined, LightbulbOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

function Introduction() {

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ margin: '20px' }}><img src={logoImage} alt="AI Board Logo" /></div>
          <div style={{ height: '400px', width: '100%', backgroundColor: '#0061D5' }}>
            <Grid container>
              <Grid item xs={5}>
                <div style={{ color: 'white', paddingTop: '100px', paddingLeft: '120px', userSelect: 'none', fontSize: '3em', fontWeight: '300' }}>Delivering AI that works<br />for your business.</div>
                <div style={{ color: 'white', paddingTop: '10px', paddingLeft: '120px', width: '500px', textAlign: 'center', fontSize: '3em', fontWeight: '300' }}>
                  <Link to="/dashboard">
                    <button style={{ fontWeight: '300', padding: '12px 24px', cursor: 'pointer', userSelect: 'none', borderColor: 'white', border: '1px solid white', color: 'white', borderRadius: '10px', backgroundColor: '#0061D5' }}>Coming soon...</button>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={7}>
                <img
                  src={screenshotImage}
                  alt="AI Board Screenshot"
                  style={{
                    position: 'relative',
                    top: '50px',
                    borderRadius: '5px',
                    WebkitBoxReflect: 'below 2px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(90%, transparent) , to(rgba(250, 250, 250, 0.2)))'
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid container style={{ padding: '100px' }}>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <ExploreOutlined style={{ fontSize: '50px', color: '#F16430' }} />
          <div style={{ fontSize: '28px', color: '#F16430' }} >AI Strategy</div>
          <div style={{ textAlign: 'justify', padding: '10px 30px' }}>AI Board helps companies develop AI strategies aligned with their business goals empowering organizations to identify their key objectives, analyze market trends, and strategically leverage AI technologies. By seamlessly integrating business intelligence and AI capabilities, our software empowers companies to unlock new opportunities, optimize operations, and increase profitability.</div>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <LightbulbOutlined style={{ fontSize: '50px', color: '#F16430' }} />
          <div style={{ fontSize: '28px', color: '#F16430' }} >Ideation & Evaluation</div>
          <div style={{ textAlign: 'justify', padding: '10px 30px' }}>With AI Board, you can explore a wide range of AI possibilities, brainstorm ideas, and assess their feasibility and potential impact. Seamlessly collaborate with team members, leverage expert insights, and evaluate the suitability of different AI technologies for your specific business needs. This feature empowers you to make informed decisions, identify the most promising AI solutions, and embark on a successful AI transformation journey.</div>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <HandymanOutlined style={{ fontSize: '50px', color: '#F16430' }} />
          <div style={{ fontSize: '28px', color: '#F16430' }} >Implementation</div>
          <div style={{ textAlign: 'justify', padding: '10px 30px' }}>Our AI workspace is a productivity-enhancing platform that empowers employees to leverage the latest AI solutions effectively. With its user-friendly interface and seamless integration, your team can easily access and utilize advanced AI tools to drive impactful business results. By embracing our AI workspace, your organization can stay at the forefront of AI technology, maximizing employee potential and driving tangible business outcomes.</div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Introduction;
