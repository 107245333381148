
// FilterSection.js
import React, { useCallback, useEffect } from 'react';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ChatHistoryRecord } from '../../types/chatHistoryRecord';

interface FilterSectionProps {
    employeeGuidFilter: string;
    setEmployeeGuidFilter: React.Dispatch<React.SetStateAction<string>>;
    startDateFilter: Date | null;
    setStartDateFilter: React.Dispatch<React.SetStateAction<Date | null>>;
    endDateFilter: Date | null;
    setEndDateFilter: React.Dispatch<React.SetStateAction<Date | null>>;
    keywordFilter: string;
    setKeywordFilter: React.Dispatch<React.SetStateAction<string>>;
    policyViolations: { [key: string]: boolean };
    setPolicyViolations: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    chatHistoryData: ChatHistoryRecord[];
    setFilteredData: React.Dispatch<React.SetStateAction<ChatHistoryRecord[]>>;
}

function FilterSection ({
    employeeGuidFilter,
    setEmployeeGuidFilter,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    keywordFilter,
    setKeywordFilter,
    policyViolations,
    setPolicyViolations,
    chatHistoryData,
    setFilteredData
}: FilterSectionProps) {

    // Apply filters
    const applyFilters = useCallback(() => {
        let filteredResults = chatHistoryData;

        // Apply filters based on filter states
        if (employeeGuidFilter) {
            filteredResults = filteredResults.filter((row: ChatHistoryRecord) => row.createdBy.toLowerCase().includes(employeeGuidFilter.toLowerCase()));
        }
        if (startDateFilter) {
            filteredResults = filteredResults.filter((row: ChatHistoryRecord) => row.createdAt >= startDateFilter);
        }
        if (endDateFilter) {
            filteredResults = filteredResults.filter((row: ChatHistoryRecord) => row.createdAt <= endDateFilter);
        }
        if (keywordFilter) {
            filteredResults = filteredResults.filter((row: ChatHistoryRecord) => 
                row.chatRequest.toLowerCase().includes(keywordFilter.toLowerCase()) ||
                row.chatResponse.toLowerCase().includes(keywordFilter.toLowerCase())
            );
        }
        if (Object.keys(policyViolations).some((policy) => policyViolations[policy])) {
            filteredResults = filteredResults.filter((row: ChatHistoryRecord) =>
                Object.keys(policyViolations).some((policy) => policyViolations[policy] && row.policyViolationType && row.policyViolationType.includes(policy))
            );
        }

        setFilteredData(filteredResults);
    },[employeeGuidFilter, startDateFilter, endDateFilter, keywordFilter, policyViolations, chatHistoryData, setFilteredData]);

    const clearFilters = () => {
        setEmployeeGuidFilter('');
        setStartDateFilter(null);
        setEndDateFilter(null);
        setKeywordFilter('');
        setPolicyViolations({
            'IP Policy': false, 
            'PII Policy': false, 
            'Medical Information': false, 
            'Employment Information': false
        });
        setFilteredData(chatHistoryData);
    };

    useEffect(() => {
        applyFilters();
        console.log('FilterSection useEffect');
    }
    , [applyFilters]);
  
    return (
        <div>
            <TextField
                label="Search Employee Guid"
                variant="outlined"
                value={employeeGuidFilter}
                onChange={(e) => setEmployeeGuidFilter(e.target.value)}
                onBlur={applyFilters}
            />
            <DatePicker
                label="Start Date"
                value={startDateFilter}
                onChange={(newValue: Date | null) => setStartDateFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
                label="End Date"
                value={endDateFilter}
                onChange={(newValue: Date |  null) => setEndDateFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
            />
            <TextField
                label="Search Keyword"
                variant="outlined"
                value={keywordFilter}
                onChange={(e) => setKeywordFilter(e.target.value)}
                onBlur={applyFilters}
            />
            <FormControl
                sx={{ minWidth: 140 }}
                variant="outlined"
            >
                <InputLabel id="policy-violation-select-label">Select Policies</InputLabel>
                <Select
                    id="policy-violation-select"
                    multiple
                    label="Select Policies"
                    value={Object.keys(policyViolations).filter((policy) => policyViolations[policy])}
                    onChange={(e) => {
                        const selectedPolicies = e.target.value as string[];
                        const newPolicyViolations = { ...policyViolations };
                        Object.keys(newPolicyViolations).forEach((policy) => {
                            newPolicyViolations[policy] = selectedPolicies.includes(policy);
                        });
                        setPolicyViolations(newPolicyViolations);
                    }}
                    renderValue={(selected) => selected.join(', ')}
                >

                    <MenuItem disabled value="">
                        <ListItemText primary="Select Policies" />
                    </MenuItem>
                    {Object.keys(policyViolations).map((policy) => (
                    <MenuItem key={policy} value={policy}>
                        <Checkbox checked={policyViolations[policy]} />
                        <ListItemText primary={policy} />
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={applyFilters} sx={{ ml: '10px', mt: '5px' }}>
                Apply Filters
            </Button> 
            <Button variant="contained" color="primary" onClick={clearFilters} sx={{ ml: '10px', mt: '5px' }}>
                Reset
            </Button>
        </div>
    )
};

export default FilterSection;


