import {
  Button,
  Grid,
  LinearProgress,
} from "@mui/material";

import logoImage from '../images/ai-board-logo-blue.png'
import CheckboxOption from "./CheckboxOption";

interface OnboardingModalProps {
    nextButtonHandler: () => void;
    backButtonHandler?: () => void;
    propgressComplete: number;
    icons?: JSX.Element[];
    mainTitle: string;
    subTitle: string;
    optionChecked: { [key: string]: boolean };
    saveOption: (option: string) => void;
}

function OnboardingModal(props: OnboardingModalProps) {
  return (
    <div style={{ width: '100%', height: '100%', background: 'rgb(0,97,213) linear-gradient(180deg, rgba(0,97,213,1) 0%, rgba(65,131,210,1) 100%)' }}>
      <div style={{
        width: '60%',
        margin: '5vh auto',
        backgroundColor: 'white',
        borderRadius: '10px',
      }}>
        <img style={{ padding: '20px' }} src={logoImage} alt="AI Board Logo" />
        <LinearProgress style={{ width: '90%', margin: 'auto' }} variant="determinate" value={props.propgressComplete} />
        <div style={{ fontSize: '2.2em', fontWeight: '600', color: '#444', margin: '20px 70px 10px 70px', letterSpacing: '-.028em' }}>
          {props.mainTitle}
        </div>
        <div style={{ fontSize: '1.6em', fontWeight: '300', color: '#444', margin: '10px 70px', letterSpacing: '-.028em' }}>
          {props.subTitle}
        </div>

        <Grid container spacing={6} style={{ padding: '40px' }}>
        {Object.entries(props.optionChecked).map(([label, checked], index) => (
          <Grid item xs={6} key={index}>
            <CheckboxOption
              checked={checked}
              label={label}
              icon={props.icons ? props.icons[index] : undefined}
              onClick={() => props.saveOption(label)}
            />
          </Grid>
        ))}
        </Grid>
        <Grid container style={{ padding: '30px 60px' }}>
          <Grid item xs={6}>
            <Button onClick={props.backButtonHandler} size="large" variant="text">Back</Button>
          </Grid>
          <Grid item xs={6}>
            <div style={{
              textAlign: 'right'
            }}>
              <Button onClick={props.nextButtonHandler} size="large" variant="contained">Next</Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default OnboardingModal;
