import React from "react";

import Introduction from "./Introduction";
import Cookies from "universal-cookie";

const cookies = new Cookies();
cookies.remove("accessToken");

function Presentation() {

  return (
    <React.Fragment>
      <Introduction />
    </React.Fragment>
  );
}

export default Presentation;
