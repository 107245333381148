import React, { useEffect, useState } from 'react';

interface CheckboxOptionProps {
  checked: boolean;
  label: string;
  icon?: JSX.Element;
  onClick?: (event: any) => void;
}

function CheckboxOption(props: CheckboxOptionProps) {
  const [checkedValue, setCheckedValue] = useState(props.checked);
  const onChange = props.onClick;

  useEffect(() => {
    setCheckedValue(props.checked);
  }, [props.checked]);

  const handleContainerClick = () => {
    const newValue = !checkedValue;
    setCheckedValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <div
      style={{
        border: checkedValue ? '2px solid #0061D5' : '1px solid #ccc',
        margin: checkedValue ? '0px' : '1px',
        borderRadius: '5px',
        padding: '20px 40px',
        fontSize: '1.2em',
        cursor: 'pointer',
      }}
      onClick={handleContainerClick}
    >
      {/* Render either the icon or the checkbox based on the presence of props.icon */}
      {props.icon ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.cloneElement(props.icon, {
                style: {
                    color: checkedValue ? '#0061D5' : '#ccc', // Set icon color here
                },
            })}
          <span
            style={{
              marginLeft: '12px',
              color: checkedValue ? '#0061D5' : '#222',
              userSelect: 'none',
            }}
          >
            {props.label}
          </span>
        </div>
      ) : (
        <div 
            style={{ display: 'flex', alignItems: 'center' }}
        >
            <input
                type="checkbox"
                checked={checkedValue}
                readOnly
                style={{ 
                    position: 'absolute',
                    cursor: 'pointer',
                    marginRight: '30px',
                    width: '15px',
                    height: '15px',
                }}
            />   
            <span
                style={{
                  marginLeft: '30px',
                  color: checkedValue ? '#0061D5' : '#222',
                  userSelect: 'none',
                }}
            >
                {props.label}
            </span>
        </div>
      )}
    </div>
  );
}

export default CheckboxOption;
