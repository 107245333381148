import React from "react";
import { Helmet } from "react-helmet-async";
import { Grid as GridIcon } from "react-feather";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid, Typography,
} from "@mui/material";

function Products() {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid md={10} item>
          <h1><GridIcon style={{ position: 'relative', top: '2px', marginRight: '15px' }} />OpenAI: ChatGPT 4</h1>
        </Grid>
      </Grid>
      <Grid justifyContent="space-between" container spacing={6} sx={{ mb: 2 }}>
        <Grid md={10} sx={{ ml: 8 }} item>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ml: 1 }}><b>Summary</b></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 40px'}}>
              <div>
                <Grid container sx={{ mb: 6 }}>
                  <Grid xs={12} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Description</div>
                    <div>The ChatGPT and GPT-4 models are language models that are optimized for conversational interfaces. The models behave differently than the older GPT-3 models. Previous models were text-in and text-out, meaning they accepted a prompt string and returned a completion to append to the prompt. However, the ChatGPT and GPT-4 models are conversation-in and message-out. The models expect input formatted in a specific chat-like transcript format, and return a completion that represents a model-written message in the chat. While this format was designed specifically for multi-turn conversations, you'll find it can also work well for non-chat scenarios too.</div>
                  </Grid>
                  <Grid xs={6}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Vendor</div>
                    <div>OpenAI</div>
                  </Grid>
                </Grid>
              </div>

            </AccordionDetails>
          </Accordion>

        </Grid>
      </Grid >

      <Grid justifyContent="space-between" container spacing={6} sx={{ mb: 2 }}>
        <Grid md={10} sx={{ ml: 8 }} item>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ml: 1 }}><b>Approved Use Policy</b></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 40px'}}>
              <div>
                <Grid container sx={{ mb: 6 }}>
                  <Grid xs={6}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Approval Status</div>
                    <div>Limited Approval: Experimentation - Internal Only</div>
                  </Grid>
                  <Grid xs={6}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Last Update</div>
                    <div>April 12, 2023</div>
                  </Grid>
                </Grid>

                <Grid container sx={{ mb: 6 }}>
                  <Grid xs={12} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Guidance</div>
                    <div>While ChatGPT (including ChatGPT 4) continues to generate a lot of interest and shows promise in a number of key areas, we continue to see high risk in using ChatGPT for critical and sensitive business functions including HR, accounting, and customer interactions. We recommend teams continue to experiment with ChatGPT 4 internally and carefully monitor the results for bias and inaccurate information.</div>
                  </Grid>
                </Grid>
              </div>

            </AccordionDetails>
          </Accordion>

        </Grid>
      </Grid >

      <Grid justifyContent="space-between" container spacing={6} sx={{ mb: 2 }}>
        <Grid md={10} sx={{ ml: 8 }} item>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ml: 1 }}><b>Risk Management</b></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 40px'}}>
              <div>
                <Grid container sx={{ mb: 6 }}>
                  <Grid xs={12} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Overall Risk</div>
                    <div>High - ChatGPT has a medium to high risk across most of the key risk categories. Widespread external use of ChatGPT could have a significant, negative impact on the organization, our employees, and customers.</div>
                  </Grid>
                  <Grid xs={12} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Bias Risk</div>
                    <div>Medium - ChatGPT has some controls in place to limit bias, but there are many specific examples of bias, especially gender bias, in ChatGPT responses.</div>
                  </Grid>
                  <Grid xs={12} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Accuracy Risk</div>
                    <div>High - While many of the responses provided by ChatGPT are useful and accurate, there are widespread examples of significant inaccuracies. The problem is exacerbated by the fact that ChatGPT will present inaccurate or false information with a tremndous amount of confidence and authority making it even more difficult to distinguish between accurate and inaccurate responses.</div>
                  </Grid>
                  <Grid xs={12} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Privacy Risk</div>
                    <div>High - ChatGPT was trained on a vast public internet data set that often contains people's private information. ChatGPT recently introduced additional privacy controls and has been approved for use in Italy after a previous, temporary ban over privacy concerns. <a href="https://techcrunch.com/2023/04/28/chatgpt-resumes-in-italy/">https://techcrunch.com/2023/04/28/chatgpt-resumes-in-italy/</a></div>
                  </Grid>
                  <Grid xs={12} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Legal Risk</div>
                    <div>Medium - ChatGPT was trained on massive public internet data sets included significant amounts of copyrighted material. Copyright owners are expressing concern for their material being used without permission.</div>
                  </Grid>
                </Grid>
              </div>

            </AccordionDetails>
          </Accordion>

        </Grid>
      </Grid >

      <Grid justifyContent="space-between" container spacing={6} sx={{ mb: 2 }}>
        <Grid md={10} sx={{ ml: 8 }} item>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ml: 1 }}><b>Internal Use</b></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 40px'}}>
              <div>
                <Grid container sx={{ mb: 6 }}>
                  <Grid xs={4} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Registered Projects</div>
                    <div>18</div>
                  </Grid>
                  <Grid xs={4} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Active Experiments</div>
                    <div>22</div>
                  </Grid>
                  <Grid xs={4} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Completed Experiments</div>
                    <div>31</div>
                  </Grid>
                </Grid>
              </div>

            </AccordionDetails>
          </Accordion>

        </Grid>
      </Grid >

    </React.Fragment >
  );
}

export default Products;
