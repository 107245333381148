import React from "react";
import styled from "@emotion/styled";
import PersonIcon from '@mui/icons-material/Person';

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const { logout } = useAuth0();
  const { user, isAuthenticated } = useAuth0();
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          {isAuthenticated && user ? (
            <img 
              src={user.picture} 
              alt={user.name} 
              width={40}
              height={40}
              style={{ borderRadius: '50%' }}
            /> 
          ) : (
            <PersonIcon/>
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>
          {isAuthenticated && user && (
            <div>
              <h2>{user.name}</h2>
              <p>{user.email}</p>
            </div>
          )}
        </MenuItem>
        <MenuItem
          onClick={() =>
            logout({
              logoutParams: { returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI },
            })
          }
        >
          Sign out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
