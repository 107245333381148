import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import PresentationLayout from "./layouts/Presentation";

import Policies from "./pages/Policies/Policies";
import Vendors from "./pages/Vendors/Vendors";
import Products from "./pages/Products/Products";
import ChatLogs from "./pages/ChatLogs/ChatLogs";

// Guards
import AuthenticationGuard from "./components/guards/AuthenticationGuard";

// Auth components
import Page404 from "./error/Page404";

// Landing
import Landing from "./pages/Landing";
import StrategicPriorities from "./pages/StrategicPriorities/StrategicPriorities";
import AIWorkspace from "./pages/AIWorkspace/AIWorkspace";
import AIChatEvaluation from "./pages/AIChatEvaluation/AIChatEvaluation";
import PermissionsControls from "./pages/PermissionsControls/PermissionsControls";
import EmployeeInsight from "./pages/EmployeeInsight/EmployeeInsight";

// Protected routes
import Onboarding from "./pages/Onboarding/Onboarding";
import GenAITextTemplate from "./pages/GenAITextTemplates/GenAITextTemplate";
import Dashboard from "./pages/Dashboard";
import GenAITextTemplateWorkspace from "./pages/GenAITextTemplateWorkspace/GenAITextTemplateWorkspace";

// Dashboard components
const Strategy = async(() => import("./pages/Strategy"));
const Ideas = async(() => import("./pages/Ideas"));

const routes = [
  {
    path: "/",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
  {
    path: "/",
    element: <PresentationLayout />,
    children: [
      {
        path: "/onboarding",
        element: <Onboarding />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "strategy",
        element: <Strategy />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "genai-text-template",
        element: <GenAITextTemplate />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "genai-text-template-workspace",
        element: <GenAITextTemplateWorkspace />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "ideas",
        element: <Ideas />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "permissions-controls",
        element: <PermissionsControls />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "chat-logs",
        element: <ChatLogs />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "/employee-insight",
        element: <EmployeeInsight />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "ai-workspace",
        element: <AIWorkspace />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "ai-chat-evaluation",
        element: <AIChatEvaluation />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "vendors",
        element: <Vendors />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "strategic-priorities",
        element: <StrategicPriorities />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "products",
        element: <Products />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "policies",
        element: <Policies />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthenticationGuard component={DashboardLayout} />,
    children: [
      {
        path: "policies",
        element: <Policies />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
