import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { CheckCircle, Layers, Lock, MessageSquare, Send, XCircle } from "react-feather";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Accordion,
  AccordionSummary,
  Button,
  Card as MuiCard,
  CardActionArea,
  CardContent,
  Grid, 
  TextField, 
  Typography, 
  CardHeader, 
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import StarIconOutline from '@mui/icons-material/StarOutline';
import { Md5 } from 'ts-md5';
import { createChatCompletion } from '../../data'
import { useAuth0 } from "@auth0/auth0-react";

const Alert = styled(MuiAlert)(spacing);

const Card = styled(MuiCard)(spacing);

function AIWorkspace() {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const [accessToken, setAccessToken] = useState(String);

  useEffect(() => {
      (async () => {
        try {
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
              scope: "full:all"
            }
          });
          setAccessToken(token);
        } catch (e) {  // if user has not given consent already, a popup is required
          console.log(e);
          const tokenFromRedirect = await getAccessTokenWithPopup({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
              scope: "full:all"
            }
          });
          setAccessToken(tokenFromRedirect || '');
        }
      })();
  }, [getAccessTokenSilently, getAccessTokenWithPopup]);

  function delay(milliseconds: number) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  const [promptSectionExpanded, setPromptSectionExpanded] = useState(true);
  const [prompt, setPrompt] = useState("");
  const [rating, setRating] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const handleStarClick = (selectedRating: number) => {
    // make api call here
    setRating(selectedRating);
  };

  const openPromptSection = () => {
    if (promptSectionExpanded === false) {
      const sendPromptButton1 = document.getElementById('send-prompt-button-1');
      const sendPromptButton2 = document.getElementById('send-prompt-button-2');
      const sendPromptButton3 = document.getElementById('send-prompt-button-3');
      const sendPromptButton4 = document.getElementById('send-prompt-button-4');

      if (sendPromptButton1 && sendPromptButton2 && sendPromptButton3 && sendPromptButton4) {
        sendPromptButton1.style.display = "inline-flex";
        sendPromptButton2.style.display = "none";
        sendPromptButton3.style.display = "none";
        sendPromptButton4.style.display = "none";
      }

      setPromptSectionExpanded(true);
    }
  }

  const trueBlueSecrets = [
    "TrueBlue",
    "PeopleReady",
    "Staff Management",
    "SMX",
    "SIMOS",
    "PeopleScout",
    "Steven",
    "Cooper",
    "Jeff",
    "Dirks",
    "TBI"
  ]

  const tradeSecrets = trueBlueSecrets;

  const checkForProtectedContent = (promptText: string) => {
    const originalContent = promptText.split(" ");
    const protectedContentArray = new Array<string>;

    let ipString = tradeSecrets.join(" ")
    ipString = ipString.toLowerCase();
    ipString = ipString.replace(/[0-9]/g, '').replace(/[^a-z ]/g, '').replace(/\s{2,}/g, ' ');
    console.log(ipString)
    const ipArray = ipString.split(" ");
    const protectedContent = new Array<string>;

    ipArray.map(function (value, index) {
      protectedContent[index] = Md5.hashStr(value)
      return null;
    });

    console.log(protectedContent)

    originalContent.map(function (value, index) {
      protectedContentArray[index] = Md5.hashStr(value.toLowerCase())
      return null;
    });

    console.log(protectedContentArray)

    const filteredProtectedContent = protectedContentArray.filter(val => protectedContent.includes(val));

    console.log(filteredProtectedContent);

    if (filteredProtectedContent.length > 0) {
      return true
    } else {
      return false
    }
  }

  const acceptPolicy = async () => {
    const acceptPolicy = document.getElementById('policy-agreement');
    const chatMainWindow = document.getElementById('chat-main-window');


    if (acceptPolicy && chatMainWindow) {
      acceptPolicy.style.display = "none";
      chatMainWindow.style.display = "initial";
    }
  }

  const resetAlert = async () => {
    const alertBox = document.getElementById('alert-box');
    if (alertBox) {
      alertBox.style.display = "none";
    }
  }

  const handleWindowOpen = () => {
    setOpen(true);
  };

  const handleWindowClose = () => {
    setOpen(false);
  };

  const closeWindow = (id: string) => {
    const chatResponse = document.getElementById(id);
    chatResponse?.remove();
    handleWindowClose();
  }

  const sendPrompt = async () => {
    const sendPromptButton1 = document.getElementById('send-prompt-button-1');
    const sendPromptButton2 = document.getElementById('send-prompt-button-2');
    const sendPromptButton3 = document.getElementById('send-prompt-button-3');
    const sendPromptButton4 = document.getElementById('send-prompt-button-4');
    const promptTextField = document.getElementById('prompt-text-field');
    const chatResponses = document.getElementById('chat-responses');

    if (sendPromptButton1 && sendPromptButton2 && sendPromptButton3 && sendPromptButton4 && promptTextField) {
      sendPromptButton1.style.display = "none";
      sendPromptButton2.style.display = "inline-flex";
      await delay(2000);

      const promptTextField = document.getElementById('prompt-text-field');
      const promptText = (promptTextField as HTMLInputElement).value;

      if (checkForProtectedContent(promptText)) {
        sendPromptButton1.style.display = "inline-flex";
        sendPromptButton2.style.display = "none";
        const alertBox = document.getElementById('alert-box');
        if (alertBox) {
          alertBox.style.display = "initial";
        }
        return;
      }
      sendPromptButton2.style.display = "none";
      sendPromptButton3.style.display = "inline-flex";
      await delay(1500);
      sendPromptButton3.style.display = "none";
      sendPromptButton4.style.display = "inline-flex";
      await delay(1500);

      const prompt = {
        prompt: promptText,
        provider: "OpenAI",
        model: "gpt-3.5-turbo",
        temperature: 0.7
      };

      let chatgpt4Text = "";

      createChatCompletion(
        accessToken,
        prompt,
        (async (completionResponse) => {
          const lowerCaseResponse = completionResponse.response.toLowerCase();

          if (lowerCaseResponse.indexOf("denied medical") > -1) {
            sendPromptButton1.style.display = "inline-flex";
            sendPromptButton4.style.display = "none";
            const alertBox = document.getElementById('alert-box');
            const alertBoxMessage = document.getElementById('alert-box-message');
            if (alertBox && alertBoxMessage) {
              alertBoxMessage.innerText = "Our Acceptable Use Policy does not allow the use of AI for medical information or advice.";
              alertBox.style.display = "initial";
            }
            return;
          } else if (lowerCaseResponse.indexOf("denied hiring") > -1) {
              sendPromptButton1.style.display = "inline-flex";
              sendPromptButton4.style.display = "none";
              const alertBox = document.getElementById('alert-box');
              const alertBoxMessage = document.getElementById('alert-box-message');
              if (alertBox && alertBoxMessage) {
                alertBoxMessage.innerText = "Our Acceptable Use Policy does not allow the use of AI for recruiting and hiring.";
                alertBox.style.display = "initial";
              }
              return;
            } else if (lowerCaseResponse.indexOf("denied financial") > -1) {
              sendPromptButton1.style.display = "inline-flex";
              sendPromptButton4.style.display = "none";
              const alertBox = document.getElementById('alert-box');
              const alertBoxMessage = document.getElementById('alert-box-message');
              if (alertBox && alertBoxMessage) {
                alertBoxMessage.innerText = "Our Acceptable Use Policy does not allow the use of AI for investment or financial advice.";
                alertBox.style.display = "initial";
              }
              return;
            } else {
            setPrompt(": " + (promptTextField as HTMLInputElement).value)
            setPromptSectionExpanded(false);

            if (chatResponses) {
              chatResponses.style.display = 'initial';
            }

            chatgpt4Text = completionResponse.response;
            console.log(completionResponse);

            const chatgpt4Array = chatgpt4Text.split(" ");

            const chatgpt4Window = document.getElementById('chatgpt4-window');

            for (let i = 0; i < chatgpt4Array.length; i++) {
              await delay(50)
              if (chatgpt4Window) {
                chatgpt4Window.append(chatgpt4Array[i] + " ")
              }
            }
          }
        }),
        ((e) => { console.log(e) }),
      )

      // const chatgpt4Text = "As a marathon runner, I've been on a ceaseless search for the perfect pair of athletic shoes - a search that I believe has ended with the Lightning Bolt Pro running shoes. These shoes strike an impressive balance of comfort and stability with their dual-density midsole that effectively absorbs shock, providing an energetic bounce in return, all while being impressively light. The upper is crafted from a breathable mesh material which keeps feet cool during long runs, reduces the risk of blisters and hot spots, and is designed to snugly fit with a secure heel counter. Equally noteworthy is the durable rubber outsole that provides excellent traction on both dry and wet surfaces, ensuring reliable performance in all weather conditions. Despite being a bit pricey, the Lightning Bolt Pro shoes - with their superior quality, performance, durability, and a range of vibrant color combinations - represent excellent value for money, making them a highly recommended choice for both competitive runners and fitness enthusiasts.";


    }
  }

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container>
        <Grid md={12} item>
          <div style={{ fontSize: '2em', fontWeight: 'bold', marginTop: '-40px', marginBottom: '20px' }}>
            <MessageSquare style={{ position: 'relative', top: '2px', marginRight: '15px' }} />
            AI Chat Workspace
          </div>
        </Grid>
      </Grid>

      <div id="policy-agreement">
        <Grid container>
          <Grid xs={12} item={true}>
            <Card sx={{ mb: 6, ml: 15, mr: 18 }}>
              <CardHeader
                title="ChatGPT Policy (v 2.1)"
              />
              <CardContent>
                <div style={{ margin: '0px 10px 40px 10px', padding: '15px 20px', height: '400px', overflow: 'auto' }}>
                  <p>This policy governs the use of OpenAI's ChatGPT tool within our organization, setting guidelines to ensure the protection of Intellectual Property (IP), trade secrets, and customer data. This policy applies to all employees, contractors, and third-party vendors associated with our organization.</p>
                  <p>ChatGPT should be used as a resource for professional and job-related tasks only. Its use for personal or inappropriate purposes is strictly prohibited. Importantly, users must never share proprietary data, IP, or trade secrets with ChatGPT, given that it does not have the capacity to protect such sensitive information.</p>
                  <p>The sharing of customer data, including personally identifiable information (PII), financial data, or other sensitive information with ChatGPT is also strictly forbidden, in compliance with our stringent data privacy rules.</p>
                  <p>Users must adhere to established company security protocols when using ChatGPT, ensuring secure and private internet connections, and only using approved devices and networks. Confidentiality of data obtained from or through ChatGPT is paramount, and no such data should be disclosed without proper authorization.</p>
                  <p>All users are required to undergo training on the functionality and limitations of ChatGPT, especially its handling of confidential information. The use of ChatGPT should always comply with all local, national, and international data protection laws and regulations. Monitoring and logging of ChatGPT usage may be performed for audit and compliance purposes.</p>
                  <p>Non-compliance with this policy may lead to disciplinary actions, which could include termination of employment or contracts. The policy will be regularly reviewed and updated to align with regulatory requirements, changes in ChatGPT's capabilities, and the evolving needs of our company.</p>
                  <p>By using ChatGPT, users acknowledge they have read, understood, and agree to this policy. The executive leadership team of our organization fully endorses this policy.</p>
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Button id="accept-button" onClick={acceptPolicy} style={{ width: '350px', height: '44px' }} variant="contained" color="success">I have read and agree to the policy above.</Button>
                </div>
              </CardContent>
            </Card>

          </Grid>
        </Grid>

      </div>

      <div id="chat-main-window" style={{ display: 'none' }}>

        <Grid justifyContent="space-between" container sx={{ mb: 4 }}>
          <Grid md={12} item>
            <Accordion id="prompt-section" expanded={promptSectionExpanded} onClick={openPromptSection}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ ml: 1 }}><b>Prompt{prompt}</b></Typography>
              </AccordionSummary>
              <Card mb={10} mr={10}>
                <CardActionArea>
                  <CardContent>
                    <Grid container sx={{ mb: 6 }}>
                      <Grid xs={12} item={true}>
                        <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px', width: '100%', textAlign: 'center' }}>Enter a prompt</div>
                        <div style={{ padding: '20px 20% 0px 20%' }}>
                          <TextField
                            onFocus={resetAlert}
                            id="prompt-text-field"
                            label="Ask a question or make a request"
                            multiline
                            rows={6}
                            fullWidth
                          />
                        </div>
                        <div id="alert-box" style={{ marginTop: '20px', textAlign: 'center', display: 'none' }}>
                          <Alert id="alert-box-message" style={{ width: '60%', textAlign: 'center', margin: '20px auto 0px auto' }} variant="outlined" severity="warning">This prompt includes potentially confidential information.</Alert>
                        </div>
                      </Grid>

                      <Grid xs={12} item={true}>
                        <div style={{
                          textTransform: 'uppercase',
                          fontSize: '0.8em',
                          color: "#bbb",
                          marginBottom: '12px',
                          marginTop: '40px',
                          width: '100%',
                          textAlign: 'center'
                        }}>
                          <Button id="send-prompt-button-1" onClick={sendPrompt} style={{ width: '250px', height: '44px' }} variant="contained" color="success" endIcon={<Send />} >Send Prompt</Button>
                          <Button id="send-prompt-button-2" style={{ width: '250px', height: '44px', display: 'none' }} variant="contained" color="success" endIcon={<Lock />} >Checking for Protected Data...</Button>
                          <Button id="send-prompt-button-3" style={{ width: '250px', height: '44px', display: 'none' }} variant="contained" color="success" endIcon={<Layers />} >Optimizing Prompt...</Button>
                          <Button id="send-prompt-button-4" style={{ width: '250px', height: '44px', display: 'none' }} variant="contained" color="success" endIcon={<CheckCircle />} >Sent</Button>
                        </div>
                      </Grid>

                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>

            </Accordion>

          </Grid>
        </Grid >
      </div>

      <div id="chat-responses" style={{ display: 'none' }}>
        <Grid container>
          <Grid xs={2} item={true}>
            &nbsp;
          </Grid>
          <Grid xs={8} item={true}>
            <Card sx={{ mb: 6, ml: 6, mr: 3 }} id="response">
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large" onClick={handleWindowOpen}>
                    <XCircle />
                  </IconButton>
                }
              />
              <CardContent>
                  <div style={{ marginTop: '-20px', fontSize: '0.85em', color: '#d8d8d8' }}>
                    How would you rate this response?
                    {[1, 2, 3, 4, 5].map((index) => (
                      index <= rating ? 
                      <StarIcon
                        key={index}
                        onClick={() => handleStarClick(index)}
                        style={{
                          fontSize: '1.8em',
                          marginLeft: '2px',
                          display: 'inline-flex',
                          verticalAlign: 'middle',
                          cursor: 'pointer',
                          color: 'gold',
                        }}
                      />
                        :
                      <StarIconOutline
                        key={index}
                        onClick={() => handleStarClick(index)}
                        style={{
                          fontSize: '1.8em',
                          marginLeft: '2px',
                          display: 'inline-flex',
                          verticalAlign: 'middle',
                          cursor: 'pointer',
                          color: '#d8d8d8',
                        }}
                      />
                    ))}
                  </div>
              </CardContent>
            </Card>
            <Dialog open={isOpen} onClose={handleWindowClose}>
              <DialogTitle style={{textAlign: 'center'}}>
                Delete Response?
                <IconButton aria-label="settings" size="small" onClick={handleWindowClose} style={{ float: 'right' }}>
                  <XCircle />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                Are you sure you want to delete this card?
                <div style={{ marginTop: '20px' }}>
                  <Button variant="contained" style={{ float: 'right', backgroundColor: '#888', color: '#fff'}} onClick={handleWindowClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" style={{float: 'left'}} onClick={() => closeWindow('response')}>
                    Delete
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </Grid>
          <Grid xs={2} item={true}>
            &nbsp;
          </Grid>

        </Grid>

      </div>
    </React.Fragment >
  );
}

export default AIWorkspace;
