import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";

interface AuthenticationGuardType {
  component: React.FC;
}

export const AuthenticationGuard = ({ component } : AuthenticationGuardType) => {
  const Component = withAuthenticationRequired(component);
  return <Component />;
};

export default AuthenticationGuard;
