import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  IconButton,
  Modal, // Import Modal component
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import { ThemeProps } from "../../types/theme";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
    height: 42px;
`;

const TableCell = styled(MuiTableCell)`
    padding-top: 0;
    padding-bottom: 0;
`;

const TableHead = styled(MuiTableHead)`
    background-color: #1B2635;
    position: sticky;
    top: 0;
    z-index: 1;
`;

interface ChatModalProps {
    theme: ThemeProps;
    Chat: any;
    modalOpen: boolean;
    setModalOpen: any;
}

const ChatModal: React.FC<ChatModalProps> = ({ theme, Chat, modalOpen, setModalOpen}) => {

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
      <Modal 
        open={modalOpen} 
        onClose={closeModal}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        >
        <div style={{
            position: 'absolute',
            width: '75%',
            height: '50%',
            boxShadow: '24',
        }}>
            <Card>
            <CardHeader
                title={`Chat Contents:`}
                action={
                <IconButton
                    aria-label="settings"
                    size="large"
                    onClick={closeModal}
                >
                    <CloseIcon />
                </IconButton>
                }
            />

            <CardContent>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Created By</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Request</TableCell>
                        <TableCell>Response</TableCell>
                        <TableCell>Token Count</TableCell>
                        <TableCell>Policy Violation Type</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell>{Chat.createdBy}</TableCell>
                        <TableCell>
                        {Chat.createdAt ? Chat.createdAt.toLocaleString() : ""}
                        </TableCell>
                        <TableCell>{Chat.chatRequest}</TableCell>
                        <TableCell>{Chat.chatResponse}</TableCell>
                        <TableCell>{Chat.tokenCount}</TableCell>
                        <TableCell>{Chat.policyViolationType}</TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
            </Card>
        </div>
      </Modal>
  );
};

export default withTheme(ChatModal);
