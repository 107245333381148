import React from "react";
import { Helmet } from "react-helmet-async";
import { Shield } from "react-feather"; import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid, Typography,
} from "@mui/material";

function Policies() {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid md={10} item>
          <h1><Shield style={{ position: 'relative', top: '2px', marginRight: '15px' }} />Policies</h1>
        </Grid>
      </Grid>
      <Grid justifyContent="space-between" container spacing={6} sx={{ mb: 2 }}>
        <Grid md={10} sx={{ ml: 8 }} item>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ ml: 1 }}><b>ChatGPT Policy (v 2.1 05/21/2023)</b></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 40px' }}>
              <div>
                <Grid container sx={{ mb: 6 }}>
                  <Grid xs={12} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Policy</div>
                    <div>

                      <p>This policy governs the use of OpenAI's ChatGPT tool within our organization, setting guidelines to ensure the protection of Intellectual Property (IP), trade secrets, and customer data. This policy applies to all employees, contractors, and third-party vendors associated with our organization.</p>
                      <p>ChatGPT should be used as a resource for professional and job-related tasks only. Its use for personal or inappropriate purposes is strictly prohibited. Importantly, users must never share proprietary data, IP, or trade secrets with ChatGPT, given that it does not have the capacity to protect such sensitive information.</p>
                      <p>The sharing of customer data, including personally identifiable information (PII), financial data, or other sensitive information with ChatGPT is also strictly forbidden, in compliance with our stringent data privacy rules.</p>
                      <p>Users must adhere to established company security protocols when using ChatGPT, ensuring secure and private internet connections, and only using approved devices and networks. Confidentiality of data obtained from or through ChatGPT is paramount, and no such data should be disclosed without proper authorization.</p>
                      <p>All users are required to undergo training on the functionality and limitations of ChatGPT, especially its handling of confidential information. The use of ChatGPT should always comply with all local, national, and international data protection laws and regulations. Monitoring and logging of ChatGPT usage may be performed for audit and compliance purposes.</p>
                      <p>Non-compliance with this policy may lead to disciplinary actions, which could include termination of employment or contracts. The policy will be regularly reviewed and updated to align with regulatory requirements, changes in ChatGPT's capabilities, and the evolving needs of our company.</p>
                      <p>By using ChatGPT, users acknowledge they have read, understood, and agree to this policy. The executive leadership team of our organization fully endorses this policy.</p>

                    </div>
                  </Grid>
                  <Grid xs={2}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Last Updated</div>
                    <div>May 21, 2023</div>
                  </Grid>
                  <Grid xs={2}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Version</div>
                    <div>2.1</div>
                  </Grid>
                </Grid>
              </div>

            </AccordionDetails>
          </Accordion>

        </Grid>
      </Grid >

      <Grid justifyContent="space-between" container spacing={6} sx={{ mb: 2 }}>
        <Grid md={10} sx={{ ml: 8 }} item>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ ml: 1 }}><b>ChatGPT Policy (v 1.12 04/3/2023)</b></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 40px' }}>
              <div>
                <p>This policy provides guidelines for the use of a new AI tool, ChatGPT, within our organization. The policy is designed to protect the organization's Intellectual Property (IP), trade secrets, and customer data. It applies to all employees, contractors, and third-party vendors.</p>
                <p>ChatGPT is a powerful AI tool that can help with various job-related tasks. However, as we're still learning about its full capabilities and limitations, it is crucial that employees use the tool cautiously and responsibly. Therefore, the use of ChatGPT for personal purposes or inappropriate activities is strictly prohibited.</p>
                <p>The security of our proprietary data, IP, and trade secrets is a top priority. As we're still exploring the data security features of ChatGPT, employees should avoid sharing such sensitive information with the tool.</p>
                <p>Customer data privacy is of paramount importance to our organization. Even though we don't fully understand how ChatGPT handles sensitive data, we must assume that sharing customer data with ChatGPT is not secure. Therefore, sharing any form of customer data with ChatGPT is strictly forbidden.</p>
                <p>Users must follow all established company security protocols when using ChatGPT, including secure internet connections and approved devices. Users should not disclose any data obtained from or through ChatGPT without proper authorization.</p>
                <p>We are in the process of developing a comprehensive training program about the usage of ChatGPT. Meanwhile, users are encouraged to be extra careful and vigilant while using this tool. The use of ChatGPT should comply with all local, national, and international data protection laws and regulations.</p>
                <p>Our IT department may monitor the use of ChatGPT for audit and compliance purposes. Failure to comply with this policy may lead to disciplinary actions, including termination of employment or contract. This policy will be regularly reviewed and updated based on our growing understanding of ChatGPT and changes in legal requirements.</p>
                <p>By using ChatGPT, users agree to this policy. The policy has been approved by the executive leadership team of our organization.</p>
              </div>

              <Grid container spacing={6} sx={{ mt: 8, mb: 2 }}>
                <Grid xs={2}>
                  <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Last Updated</div>
                  <div>April 3, 2023</div>
                </Grid>
                <Grid xs={2}>
                  <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Version</div>
                  <div>1.12</div>
                </Grid>
              </Grid>

            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid >
    </React.Fragment >
  );
}

export default Policies;
