import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { CheckCircle, Layers, Lock, Send, XCircle } from "react-feather";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Accordion,
  AccordionSummary,
  Button,
  Card as MuiCard,
  CardActionArea,
  CardContent,
  Grid, TextField, Typography, Chip, CardHeader, IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ScienceOutlined } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Md5 } from 'ts-md5';

const Alert = styled(MuiAlert)(spacing);

const Card = styled(MuiCard)(spacing);

function AIChatEvaluation() {

  function delay(milliseconds: number) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  const [promptSectionExpanded, setPromptSectionExpanded] = useState(true);
  const [prompt, setPrompt] = useState("");

  const openPromptSection = () => {
    if (promptSectionExpanded === false) {
      const sendPromptButton1 = document.getElementById('send-prompt-button-1');
      const sendPromptButton2 = document.getElementById('send-prompt-button-2');
      const sendPromptButton3 = document.getElementById('send-prompt-button-3');
      const sendPromptButton4 = document.getElementById('send-prompt-button-4');

      if (sendPromptButton1 && sendPromptButton2 && sendPromptButton3 && sendPromptButton4) {
        sendPromptButton1.style.display = "inline-flex";
        sendPromptButton2.style.display = "none";
        sendPromptButton3.style.display = "none";
        sendPromptButton4.style.display = "none";
      }

      setPromptSectionExpanded(true);
    }
  }


  const nikeProducts = [
    "nike air force 1", "nike air max 270", "nike air vapormax flyknit", "nike blazer mid",
    "nike cortez", "nike dunk low", "nike free rn 5.0", "nike joyride run flyknit",
    "nike lebron 17", "nike metcon 5", "nike pegasus trail 2", "nike react infinity run",
    "nike sb dunk low", "nike zoomx vaporfly next", "nike zoom kobe 4 protro",
    "nike air jordan 1", "nike air jordan 4", "nike air jordan 11", "nike kyrie 6",
    "nike kd 13", "nike pg 4", "nike air max 90", "nike air max 95", "nike air max 97",
    "nike air max 98", "nike air max 200", "nike air max 2090", "nike air vapormax plus",
    "nike epic react flyknit 2", "nike superrep go", "nike zoom fly 3", "nike air zoom pegasus 37",
    "nike zoom gravity", "nike renew run", "nike react miler", "nike revolution 5",
    "nike court vision low", "nike wearallday", "nike air monarch iv", "nike precision iii",
    "nike roshe g", "nike zoom victory tour", "nike roshe g tour", "nike air zoom victory tour",
    "nike air zoom infinity tour", "nike react vapor 2", "nike infinity g", "nike victory g lite",
    "nike precision iv", "nike mamba fury", "nike pro hypercool tights", "nike sportswear joggers",
    "nike sb dry polo", "nike sportswear club hoodie", "nike sportswear windrunner",
    "nike dri-fit academy shorts", "nike swoosh sports bra", "nike yoga luxe crop top",
    "nike pro hijab", "nike one leggings", "nike elite basketball crew socks", "nike sb everyday max",
    "nike air huarache", "nike renew in-season tr 10", "nike air zoom pegasus 38",
    "nike court borough low 2", "nike wildhorse 7", "nike p-6000", "nike air zoom superrep 2",
    "nike flex trainer 9", "nike metcon 6", "nike legend essential 2", "nike air zoom terra kiger 7",
    "nike vapormax evo", "nike react escape run", "nike flex experience run 10",
    "nike revolution 5 flyease", "nike air zoom type", "nike blazer low", "nike court vintage premium",
    "nike waffle trainer 2", "nike air max pre-day", "nike air huarache run",
    "nike killshot og sp", "nike air max furyosa", "nike air zoom pegasus 38 flyease",
    "nike air zoom vomero 15", "nike alpha menace varsity 2", "nike zoom rize 2",
    "nike lebron witness 5", "nike air precision iii flyease", "nike kybrid s2",
    "nike precision 5 flyease", "nike alpha menace pro 2 mid", "nike renew fusion",
    "nike air max alpha trainer 4", "nike mc trainer", "nike superrep cycle", "nike savaleos",
    "nike metcon sport", "nike air zoom pegasus 38 trail", "nike pegasus trail 3",
    "nike wildhorse 8", "nike terra kiger 7"
  ];

  const checkForProtectedContent = () => {
    const promptTextField = document.getElementById('prompt-text-field');
    const promptText = (promptTextField as HTMLInputElement).value;
    const originalContent = promptText.split(" ");
    const protectedContentArray = new Array<string>;

    let nikeProductsString = nikeProducts.join()
    nikeProductsString = nikeProductsString.toLowerCase();
    nikeProductsString = nikeProductsString.replace('nike', '');
    nikeProductsString = nikeProductsString.replace(/[0-9]/g, '').replace(/[^a-z ]/g, '').replace(/\s{2,}/g, ' ');
    const nikeProductArray = nikeProductsString.split(" ");
    const nikeProtectedContent = new Array<string>;

    nikeProductArray.map(function (value, index) {
      nikeProtectedContent[index] = Md5.hashStr(value)
      return null;
    });

    originalContent.map(function (value, index) {
      protectedContentArray[index] = Md5.hashStr(value.toLowerCase())
      return null;
    });

    const filteredProtectedContent = protectedContentArray.filter(val => nikeProtectedContent.includes(val));

    console.log(filteredProtectedContent);

    if (filteredProtectedContent.length > 0) {
      return true
    } else {
      return false
    }
  }


  const resetAlert = async () => {
    const alertBox = document.getElementById('alert-box');
    if (alertBox) {
      alertBox.style.display = "none";
    }
  }

  const sendPrompt = async () => {
    const sendPromptButton1 = document.getElementById('send-prompt-button-1');
    const sendPromptButton2 = document.getElementById('send-prompt-button-2');
    const sendPromptButton3 = document.getElementById('send-prompt-button-3');
    const sendPromptButton4 = document.getElementById('send-prompt-button-4');
    const promptTextField = document.getElementById('prompt-text-field');
    const chatResponses = document.getElementById('chat-responses');

    if (sendPromptButton1 && sendPromptButton2 && sendPromptButton3 && sendPromptButton4 && promptTextField) {
      sendPromptButton1.style.display = "none";
      sendPromptButton2.style.display = "inline-flex";
      await delay(2000);
      if (checkForProtectedContent()) {
        sendPromptButton1.style.display = "inline-flex";
        sendPromptButton2.style.display = "none";
        const alertBox = document.getElementById('alert-box');
        if (alertBox) {
          alertBox.style.display = "initial";
        }
        return;
      }
      sendPromptButton2.style.display = "none";
      sendPromptButton3.style.display = "inline-flex";
      await delay(1500);
      sendPromptButton3.style.display = "none";
      sendPromptButton4.style.display = "inline-flex";
      await delay(1500);
      setPromptSectionExpanded(false);
      setPrompt(": " + (promptTextField as HTMLInputElement).value)
      if (chatResponses) {
        chatResponses.style.display = 'initial';
      }

      const chatgpt4Window = document.getElementById('chatgpt4-window');
      const googleBardWindow = document.getElementById('google-bard-window');

      const chatgpt4Text = "As a marathon runner, I've been on a ceaseless search for the perfect pair of athletic shoes - a search that I believe has ended with the Lightning Bolt Pro running shoes. These shoes strike an impressive balance of comfort and stability with their dual-density midsole that effectively absorbs shock, providing an energetic bounce in return, all while being impressively light. The upper is crafted from a breathable mesh material which keeps feet cool during long runs, reduces the risk of blisters and hot spots, and is designed to snugly fit with a secure heel counter. Equally noteworthy is the durable rubber outsole that provides excellent traction on both dry and wet surfaces, ensuring reliable performance in all weather conditions. Despite being a bit pricey, the Lightning Bolt Pro shoes - with their superior quality, performance, durability, and a range of vibrant color combinations - represent excellent value for money, making them a highly recommended choice for both competitive runners and fitness enthusiasts.";
      const googleBardText = "The [Generic Running Shoe] is a great all-around running shoe that offers a comfortable fit, responsive cushioning, and a durable upper. It is a versatile shoe that can be used for a variety of runs, from short daily runs to long training runs. The [Generic Running Shoe] is a great choice for runners of all levels.";

      const chatgpt4Array = chatgpt4Text.split(" ");
      const googleBardArray = googleBardText.split(" ");

      for (let i = 0; i < chatgpt4Array.length; i++) {
        await delay(100)
        if (chatgpt4Window) {
          chatgpt4Window.append(chatgpt4Array[i] + " ")
        }
        if (googleBardWindow && i < googleBardArray.length) {
          googleBardWindow.append(googleBardArray[i] + " ")
        }
      }
    }

  }

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container>
        <Grid md={12} item>
          <div style={{ fontSize: '2em', fontWeight: 'bold', marginTop: '-40px', marginBottom: '20px' }}>
            <ScienceOutlined style={{ position: 'relative', fontSize: '1.2em', top: '6px', marginRight: '15px' }} />
            AI Chat Evaluation
          </div>
        </Grid>
      </Grid>

      <div id="chat-main-window">

        <Grid justifyContent="space-between" container sx={{ mb: 4 }}>
          <Grid md={12} item>
            <Accordion id="prompt-section" expanded={promptSectionExpanded} onClick={openPromptSection}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ ml: 1 }}><b>Prompt{prompt}</b></Typography>
              </AccordionSummary>
              <Card mb={10} mr={10}>
                <CardActionArea>
                  <CardContent>
                    <Grid container sx={{ mb: 6 }}>
                      <Grid xs={12}>
                        <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px', width: '100%', textAlign: 'center' }}>Enter a prompt</div>
                        <div style={{ padding: '20px 20% 0px 20%' }}>
                          <TextField
                            onFocus={resetAlert}
                            id="prompt-text-field"
                            label="Ask a question or make a request"
                            multiline
                            rows={2}
                            fullWidth
                          />
                        </div>
                        <div id="alert-box" style={{ marginTop: '20px', textAlign: 'center', display: 'none' }}>
                          <Alert style={{ width: '40%', margin: '20px auto 0px auto' }} variant="outlined" severity="warning">This prompt includes potentially confidential information.</Alert>
                        </div>
                      </Grid>

                      <Grid xs={12}>
                        <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '12px', marginTop: '40px', width: '100%', textAlign: 'center' }}>Choose your model(s)</div>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <Chip color="default" label="ChatGPT 3.5" style={{ margin: '8px' }} />
                          <Chip color="primary" label="ChatGPT 4" style={{ margin: '8px' }} />
                          <Chip color="primary" label="Google Bard" style={{ margin: '8px' }} />
                          <Chip color="default" label="LLaMa" style={{ margin: '8px' }} />
                        </div>
                      </Grid>

                      <Grid xs={12}>
                        <div style={{
                          textTransform: 'uppercase',
                          fontSize: '0.8em',
                          color: "#bbb",
                          marginBottom: '12px',
                          marginTop: '40px',
                          width: '100%',
                          textAlign: 'center'
                        }}>
                          <Button id="send-prompt-button-1" onClick={sendPrompt} style={{ width: '250px', height: '44px' }} variant="contained" color="success" endIcon={<Send />} >Send Prompt</Button>
                          <Button id="send-prompt-button-2" style={{ width: '250px', height: '44px', display: 'none' }} variant="contained" color="success" endIcon={<Lock />} >Checking for Protected Data...</Button>
                          <Button id="send-prompt-button-3" style={{ width: '250px', height: '44px', display: 'none' }} variant="contained" color="success" endIcon={<Layers />} >Optimizing Prompt...</Button>
                          <Button id="send-prompt-button-4" style={{ width: '250px', height: '44px', display: 'none' }} variant="contained" color="success" endIcon={<CheckCircle />} >Sent</Button>
                        </div>
                      </Grid>

                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>

            </Accordion>

          </Grid>
        </Grid >
      </div>

      <div id="chat-responses" style={{ display: 'none' }}>
        <Grid container>
          <Grid xs={6}>
            <Card sx={{ mb: 6, ml: 6, mr: 3 }}>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <XCircle />
                  </IconButton>
                }
                title="ChatGPT 4"
              />
              <CardContent>
                <div style={{ marginTop: '-20px', fontSize: '0.85em', color: '#d8d8d8' }}>
                  How would you rate this response?
                  <StarOutlineIcon style={{ fontSize: '1.8em', marginLeft: '6px', color: '#d8d8d8', display: 'inline-flex', verticalAlign: 'middle' }} />
                  <StarOutlineIcon style={{ fontSize: '1.8em', marginLeft: '2px', color: '#d8d8d8', display: 'inline-flex', verticalAlign: 'middle' }} />
                  <StarOutlineIcon style={{ fontSize: '1.8em', marginLeft: '2px', color: '#d8d8d8', display: 'inline-flex', verticalAlign: 'middle' }} />
                  <StarOutlineIcon style={{ fontSize: '1.8em', marginLeft: '2px', color: '#d8d8d8', display: 'inline-flex', verticalAlign: 'middle' }} />
                  <StarOutlineIcon style={{ fontSize: '1.8em', marginLeft: '2px', color: '#d8d8d8', display: 'inline-flex', verticalAlign: 'middle' }} />
                </div>
                <div id="chatgpt4-window" style={{ border: 'solid 1px #888', margin: '14px 10px', padding: '15px 20px', borderRadius: '8px', height: '400px' }}>
                </div>
              </CardContent>
            </Card>

          </Grid>
          <Grid xs={6}>
            <Card sx={{ mb: 6, ml: 3, mr: 6 }}>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <XCircle />
                  </IconButton>
                }
                title="Google Bard"
              />
              <CardContent>
                <div style={{ marginTop: '-20px', fontSize: '0.85em', color: '#d8d8d8' }}>
                  How would you rate this response?
                  <StarOutlineIcon style={{ fontSize: '1.8em', marginLeft: '6px', color: '#d8d8d8', display: 'inline-flex', verticalAlign: 'middle' }} />
                  <StarOutlineIcon style={{ fontSize: '1.8em', marginLeft: '2px', color: '#d8d8d8', display: 'inline-flex', verticalAlign: 'middle' }} />
                  <StarOutlineIcon style={{ fontSize: '1.8em', marginLeft: '2px', color: '#d8d8d8', display: 'inline-flex', verticalAlign: 'middle' }} />
                  <StarOutlineIcon style={{ fontSize: '1.8em', marginLeft: '2px', color: '#d8d8d8', display: 'inline-flex', verticalAlign: 'middle' }} />
                  <StarOutlineIcon style={{ fontSize: '1.8em', marginLeft: '2px', color: '#d8d8d8', display: 'inline-flex', verticalAlign: 'middle' }} />
                </div>
                <div id="google-bard-window" style={{ border: 'solid 1px #888', margin: '14px 10px', padding: '15px 20px', borderRadius: '8px', height: '400px' }}>
                </div>
              </CardContent>
            </Card>

          </Grid>
        </Grid>

      </div>
    </React.Fragment >
  );
}

export default AIChatEvaluation;
