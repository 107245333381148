import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { ChatHistoryRecord } from '../../types/chatHistoryRecord';

interface TableHeaderProps {
    filteredData: ChatHistoryRecord[]; //replace with actual type
    setFilteredData: React.Dispatch<React.SetStateAction<ChatHistoryRecord[]>>; // Replace with the actual type
    orderBy: string;
    setOrderBy: React.Dispatch<React.SetStateAction<string>>;
    order: 'asc' | 'desc';
    setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
}

function TableHeader(
    {
        filteredData,
        setFilteredData,
        orderBy,
        setOrderBy,
        order,
        setOrder
    } :  TableHeaderProps
) {
    const sortingFunction = (orderBy: string, order: string) => {
        return (a: any, b: any) => {
          if (order === 'asc') {
            return a[orderBy] > b[orderBy] ? 1 : -1;
          } else {
            return a[orderBy] < b[orderBy] ? 1 : -1;
          }
        };
    };
      

    const handleSortClick = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sortedData;
        if (property === 'policyViolationType') {
            sortedData = filteredData.slice().sort(sortingFunction(property, isAsc ? 'desc' : 'asc'));
        }
        else {
            sortedData = filteredData.sort(sortingFunction(property, isAsc ? 'desc' : 'asc'));
        }
        setFilteredData(sortedData);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'createdBy'}
                        direction={orderBy === 'createdBy' ? order : 'asc'}
                        onClick={() => handleSortClick('createdBy')}
                    >
                        Employee Guid
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'createdAt'}
                        direction={orderBy === 'createdAt' ? order : 'asc'}
                        onClick={() => handleSortClick('createdAt')}
                    >
                        Time Created
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'chatRequest'}
                        direction={orderBy === 'chatRequest' ? order : 'asc'}
                        onClick={() => handleSortClick('chatRequest')}
                    >
                        Chat Request
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'chatResponse'}
                        direction={orderBy === 'chatResponse' ? order : 'asc'}
                        onClick={() => handleSortClick('chatResponse')}
                    >
                        Chat Response
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'tokenCount'}
                        direction={orderBy === 'tokenCount' ? order : 'asc'}
                        onClick={() => handleSortClick('tokenCount')}
                    >
                        Token
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'policyViolationType'}
                        direction={orderBy === 'policyViolationType' ? order : 'asc'}
                        onClick={() => handleSortClick('policyViolationType')}
                    >
                        Policy Violation
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    )

}

export default TableHeader;