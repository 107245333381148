// ChatHistoryTable.js
import { useState } from 'react';
import {
    Table,
    TableContainer,
    Paper,
    TablePagination,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import Filters from './Filters';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import useData from '../../hooks/useData';

function ChatLogs() {
    const location = useLocation();
    const { chatHistoryData } = useData();
    // Pagination states
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // data state
    const [filteredData, setFilteredData] = useState(chatHistoryData);
    // Sorting states
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<string>('');
    // Filter states
    const [employeeGuidFilter, setEmployeeGuidFilter] = useState<string>('');
    const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
    const [endDateFilter, setEndDateFilter] = useState<Date | null>(new Date());
    const [keywordFilter, setKeywordFilter] = useState<string>('');
    const filterViolations = location.state?.filterViolations ?? false;
    const [policyViolations, setPolicyViolations] = useState<{ [key: string]: boolean }>({
        // TODO: Add custom policy violations here
        'IP Policy': filterViolations, 
        'PII Policy': filterViolations, 
        'Medical Information': filterViolations, 
        'Employment Information': filterViolations,
    });


    const handleChangePage = (_: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    return (
        <div>
            <Filters 
                employeeGuidFilter={employeeGuidFilter}
                setEmployeeGuidFilter={setEmployeeGuidFilter}
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
                keywordFilter={keywordFilter}
                setKeywordFilter={setKeywordFilter}
                policyViolations={policyViolations}
                setPolicyViolations={setPolicyViolations}
                chatHistoryData={chatHistoryData}
                setFilteredData={setFilteredData}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHeader
                        filteredData={filteredData}
                        setFilteredData={setFilteredData}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        order={order}
                        setOrder={setOrder}
                    />
                    <TableRows
                        filteredData={filteredData}
                        page={page}
                        rowsPerPage={rowsPerPage}
                    />
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
}

export default ChatLogs;
