import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";

import Actions from "./Actions";
import LineChart from "./LineChart";
import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";
import { useAuth0 } from "@auth0/auth0-react";
import useData from "../../hooks/useData";

const Divider = styled(MuiDivider)(spacing);


function Dashboard() {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const [accessToken, setAccessToken] = useState(String);

  const { violationData } = useData();

  useEffect(() => {
      (async () => {
        try {
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
              scope: "full:all"
            }
          });
          setAccessToken(token);
        } catch (e) {  // if user has not given consent already, a popup is required
          console.log(e);
          const tokenFromRedirect = await getAccessTokenWithPopup({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
              scope: "full:all"
            }
          });
          setAccessToken(tokenFromRedirect || '');
        }
      })();
  }, [getAccessTokenSilently, getAccessTokenWithPopup]);

  console.log(accessToken);

  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
        </Grid>
        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      <Divider my={6} />


      <Grid container spacing={6}>
        <Grid item xs={12} lg={7}>
          <LineChart/>
        </Grid>
        <Grid item xs={12} lg={5}>
          <DoughnutChart/>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Active Users"
            amount="1,302"
            chip="Today"
            percentagetext="+26%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Avg Time / Chat"
            amount="4 min 32 sec"
            chip="Today"
            percentagetext="14%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Spend"
            amount="$ 1,090"
            chip="Today"
            percentagetext="+8%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="GenAI Content Blocked"
            amount= {violationData.policyViolations}
            chip="Today"
            percentagetext={violationData.monthPercentChange + "%"}
            percentagecolor={violationData.monthPercentChange > 0 ? green[500] : red[500]}
            illustration="/static/img/illustrations/waiting.png"
            location="/chat-logs"
            state={{fromDashboard: true, filterViolations: true}}
            style={{cursor: "pointer"}}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Dashboard;
