import React from "react";
import { Helmet } from "react-helmet-async";
import { Map } from "react-feather"; 
import {
  Button,
  Grid, TextField,
} from "@mui/material";
import { Construction, ThumbDown, ThumbUp } from "@mui/icons-material";

function StrategicPriorities() {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid md={10} item>
          <h1><Map style={{ position: 'relative', top: '2px', marginRight: '15px' }} />Strategic Priorities</h1>
        </Grid>
      </Grid>

      <div style={{ width: '100%', marginTop: '20px', textAlign: 'center' }}><Construction style={{ fontSize: '4em' }} /></div>
      <div style={{ width: '100%', margin: '20px 0px 40px 0px', textAlign: 'center' }}><h1>We're still working on this feature...</h1></div>

      <div>
        With the Strategic Priorities feature, our goal is to help you define your high level goals for implementing AI. That could include deciding which areas of your business could benefit most from AI and where you need to monitor and mitigate risk as much as possible.
        The feature will enable you to collaborate across your organization to make sure your business goals and AI investments are aligned.</div>

      <div style={{ width: '100%', margin: '40px 0px 40px 0px', textAlign: 'center' }}><h2>We'd love to hear your thoughts.</h2></div>

      <div style={{ width: '50%', textAlign: 'center', margin: '50px auto 0px auto' }}>
      <TextField
          id="outlined-multiline-flexible"
          label="Share your feedback (optional)"
          multiline
          rows={4}
          fullWidth
        />
      </div>

      <div style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
          <Button style={{ margin: '10px'}} variant="contained" startIcon={<ThumbUp />}>
            Sounds great, I'd use this
          </Button>
          <Button style={{ margin: '10px'}} variant="contained" endIcon={<ThumbDown />}>
            That's not what I'm looking for
          </Button>
      </div>
    </React.Fragment >
  );
}

export default StrategicPriorities;
