import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Doughnut } from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import { green, red } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useData from "../../hooks/useData";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

import { ThemeProps } from "../../types/theme";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;


const DoughnutChart = ({ theme }: ThemeProps) => {
  const { chartData } = useData();
  const colors = ['#34eb8c', '#ebd634', '#349feb', '#ff5733'];
  const data = {
    labels: chartData.map((model: any) => model.model),
    datasets: [
      {
        data: chartData.map((model: any) => model.total),
        backgroundColor: colors,
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Chat Conversations by Model"
      />

      <CardContent>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="h4">{
              chartData.reduce((acc: number, model: any) => acc + model.total, 0)
            }
            </Typography>
            <Typography variant="caption">conversations</Typography>
          </DoughnutInner>
          <Doughnut data={data} options={options} />
        </ChartWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model</TableCell>
              <TableCell align="right">Conversations</TableCell>
              <TableCell align="right">Year % Change</TableCell>
              {/* <TableCell align="right">Month % Change</TableCell>
              <TableCell align="right">6 Month % Change</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {chartData.map((model: any, index: number) => (
              <TableRow key={model.model}>
                <TableCell>
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    marginRight: "5px",
                    backgroundColor: colors[index],
                    borderRadius: "50%",
                    display: "inline-block",
                    marginBottom: "2px",
                  }}></div>
                  {model.model}
                </TableCell>
                <TableCell align="right">{model.total}</TableCell>
                <TableCell align="right">
                  {model.yearChange > 0 ? (
                    <GreenText>
                      <ArrowUpward style={{
                        verticalAlign: "middle",
                        marginBottom: "2px",
                        height: "15px",
                        width: "15px",
                      }}/>
                      {model.yearChange}%
                    </GreenText>
                  ) : (
                    <RedText>
                      <ArrowDownward style={{
                        verticalAlign: "middle",
                        marginBottom: "2px",
                        height: "15px",
                        width: "15px",
                      }}/>
                      {model.yearChange}%
                    </RedText>
                  )}
                {/* </TableCell>
                <TableCell align="right">
                  {model.monthChange > 0 ? (
                    <GreenText>
                      <ArrowUpward />
                      {model.monthChange}%
                    </GreenText>
                  ) : (
                    <RedText>
                      <ArrowDownward />
                      {model.monthChange}%
                    </RedText>
                  )}
                  </TableCell>
                  <TableCell align="right">
                  {model.sixMonthChange > 0 ? (
                    <GreenText>
                      <ArrowUpward />
                      {model.sixMonthChange}%
                    </GreenText>
                  ) : (
                    <RedText>
                      <ArrowDownward />
                      {model.sixMonthChange}%
                    </RedText>
                  )} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default withTheme(DoughnutChart);
