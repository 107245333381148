import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { Copy } from "react-feather";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

function BasicForm() {
  return (
    <Card sx={{ maxWidth: 1000, border: 1, borderColor: "#404040" }} mb={6}>
      <CardContent>
        <Typography variant="h3" gutterBottom>
          <Copy size={20} style={{ marginRight: "10px" }} />
          Create GenAI Text Template
        </Typography>
        <form>
          <TextField
            name="templateTitle"
            label="Template title"
            fullWidth
            variant="outlined"
            my={2}
          />

          <TextField
            name="templatePurpose"
            label="Describe the purpose of this template and the solution it provides"
            fullWidth
            multiline={true}
            rows={3}
            variant="outlined"
            my={2}
          />

          <TextField
            name="templatePrompt"
            label="Enter your prompt template"
            fullWidth
            multiline={true}
            rows={12}
            variant="outlined"
            my={2}
          />

          <div style={{ width: '100%', textAlign: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              mt={3}
            >
              Save
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

function GenAITextTemplate() {
  return (
    <React.Fragment>
      <Helmet title="New Idea" />

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>AI Management</Typography>
        <Typography>GenAI Text Template</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <BasicForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default GenAITextTemplate;
