import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Line } from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { alpha } from "@mui/material/styles";

import { ThemeProps } from "../../types/theme";

import { getMonthYearArray } from "../../data";
import useData from "../../hooks/useData";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

function LineChart({ theme }: ThemeProps) {
  const { chartData } = useData();

  const labels = getMonthYearArray(new Date(), 13).reverse();
  const colors = ['#34eb8c', '#ebd634', '#349feb', '#ff5733'];

  const data = {
    labels: labels,
    datasets: chartData.map((model: any, index: number) => ({
        label: model.model,
        fill: true,
        backgroundColor: function (context: any) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
              return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, alpha(colors[index], 0.0875)); // Use a different color from the array
          gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

          return gradient;
      },
      borderColor: colors[index], // Use the same color for border
        borderDash: [4, 4],
        tension: 0.4,
        data: labels.map((label: any) => model.months[label]),
    })),
};



  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0.0)',
        },
      },
      y: {
        grid: {
          color: 'rgba(0,0,0,0.0375)',
          fontColor: '#fff',
        },
      },
    },
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Chat Conversations by Model"
      />
      <CardContent>
        <ChartWrapper>
          <Line data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}

export default withTheme(LineChart);
