import styled from "@emotion/styled";
import '../../main.css';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import {
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Card as MuiCard,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { VendorLogo } from "./VendorLogo";

const Card = styled(MuiCard)(spacing);

type CompanyInfo = {
  name: string,
  vendorStatus: string,
  logoUrl: string,
  website: string
}

function VendorCard( { name, vendorStatus, logoUrl, website }: CompanyInfo ) {
  return (
    <Card mb={10}>
      <CardActionArea>
        <VendorLogo logoUrl={logoUrl} />
        <CardContent>
          <Typography style={{ marginBottom: '20px' }} variant="h5" component="h2">
            {name}
          </Typography>
          <Grid container sx={{ mb: 6 }}>
            <Grid xs={6}>
              <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Vendor Status</div>
              <div>{vendorStatus}</div>
            </Grid>
            <Grid xs={6}>
              <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Products</div>
              <div>29</div>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 6 }}>
            <Grid xs={6}>
              <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Internal Projects</div>
              <div>13</div>
            </Grid>
            <Grid xs={6}>
              <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Internal Experiments</div>
              <div>46</div>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 6 }}>
            <Grid xs={6}>
              <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Price</div>
              <div>$$$$</div>
            </Grid>
            <Grid xs={6}>
              <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Reviews</div>
              <div style={{ display: 'flex', verticalAlign: 'middle' }}>
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarOutlineIcon />
                <div style={{ position: 'relative', top: '4px', left: '5px' }}>(39)</div>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <a href={website}>{website}</a>
      </CardActions>
    </Card>
  );
}

export default VendorCard;
