import React from "react";
import { Helmet } from "react-helmet-async";
import googleLogo from './temp_images/google-logo.png';
import microsoftLogo from './temp_images/microsoft-logo.png';
import ibmLogo from './temp_images/ibm-logo.png';
import salesforceLogo from './temp_images/salesforce-logo.png';
import openAiLogo from './temp_images/open-ai-logo.png';
import oracleLogo from './temp_images/oracle-log.png';

import {
  Grid,
} from "@mui/material";

import VendorCard from "./_VendorCard";

function Vendors() {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid md={4} item>
          <VendorCard
            name="Microsoft"
            vendorStatus="Preferred Vendor"
            logoUrl={microsoftLogo}
            website="https://www.microsoft.com/ai"
          />
        </Grid>
        <Grid md={4} item>
          <VendorCard
            name="Google"
            vendorStatus="In Review"
            logoUrl={googleLogo}
            website="https://ai.google/"
          />
        </Grid>
        <Grid md={4} item>
          <VendorCard
            name="IBM"
            vendorStatus="Approved"
            logoUrl={ibmLogo}
            website="https://openai.com"
          />
        </Grid>
        <Grid md={4} item>
          <VendorCard
            name="Oracle"
            vendorStatus="Approved"
            logoUrl={oracleLogo}
            website="https://www.oracle.com/artificial-intelligence"
          />
        </Grid>
        <Grid md={4} item>
          <VendorCard
            name="OpenAI"
            vendorStatus="In Review"
            logoUrl={openAiLogo}
            website="https://www.ibm.com/artificial-intelligence"
          />
        </Grid>
        <Grid md={4} item>
          <VendorCard
            name="Salesforce"
            vendorStatus="Approved"
            logoUrl={salesforceLogo}
            website="https://www.salesforce.com/ap/products/platform/solutions/ai-services/"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Vendors;
