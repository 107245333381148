import { ChatHistoryRecord } from '../../types/chatHistoryRecord';
import { styled } from '@mui/system';
import { TableCell } from '@mui/material';
import { TableBody, TableRow } from '@mui/material';
import { format } from 'date-fns';

interface TableRowsProps {
    filteredData: ChatHistoryRecord[];
    page: number;
    rowsPerPage: number;
}

function TableRows({
        filteredData,
        page,
        rowsPerPage,
    }: TableRowsProps) {

    const StyledTableCell = styled(TableCell)`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 50px;
        max-width: 200px;
    `;

    
    return (
        <TableBody>
            {filteredData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: ChatHistoryRecord, index: number) => (
                <TableRow key={index}>
                    <StyledTableCell>{row.createdBy}</StyledTableCell>
                    <StyledTableCell>{format(new Date(row.createdAt), 'MM/dd/yyyy')}</StyledTableCell>
                    <StyledTableCell>{row.chatRequest}</StyledTableCell>
                    <StyledTableCell>{row.chatResponse}</StyledTableCell>
                    <StyledTableCell >{row.tokenCount}</StyledTableCell>
                    <StyledTableCell>{row.policyViolationType}</StyledTableCell>
                </TableRow>
            ))}
        </TableBody>            
    )
}

export default TableRows;