import { ChatHistoryRecord } from '../types/chatHistoryRecord';
import { loremIpsum } from "lorem-ipsum";

//global data
const models = ['ChatGPT 3.5 Turbo', 'ChatGPT 4', 'Google Bard', 'Llama 2'];

const workplaceApplications = [
    {
      name: "Content Generation",
      desc: "Generate marketing copy, blog posts, or reports efficiently."
    },
    {
      name: "Automated Customer Support",
      desc: "Provide quick responses to common customer queries and issues."
    },
    {
      name: "Data Entry and Extraction",
      desc: "Automate data entry tasks and extract information from documents."
    },
    {
      name: "Language Translation",
      desc: "Translate documents and communication into multiple languages."
    },
    {
      name: "Summarization",
      desc: "Generate concise summaries of lengthy documents or articles."
    },
    {
      name: "Code Generation",
      desc: "Generate code snippets and automate software development tasks."
    },
    {
      name: "Virtual Assistants",
      desc: "Create AI-driven virtual assistants for scheduling and reminders."
    },
    {
      name: "Email Auto-Responses",
      desc: "Generate personalized email responses and manage inboxes."
    },
    {
      name: "Content Curation",
      desc: "Curate relevant content and news articles for websites and newsletters."
    },
    {
      name: "Legal Document Analysis",
      desc: "Analyze legal documents for key information and clauses."
    },
    {
      name: "Market Research",
      desc: "Gather market insights and competitive analysis from online sources."
    },
    {
      name: "Voice Assistants",
      desc: "Develop voice-controlled AI assistants for hands-free tasks."
    },
    {
      name: "Personalized Recommendations",
      desc: "Offer tailored product or content recommendations to users."
    },
    {
      name: "Data Analytics",
      desc: "Perform data analysis and generate reports from datasets."
    },
    {
      name: "Content Moderation",
      desc: "Automate the detection and filtering of inappropriate content."
    },
    {
      name: "HR and Recruitment",
      desc: "Screen job applications, answer FAQs, and schedule interviews."
    },
    {
      name: "Knowledge Base Creation",
      desc: "Build searchable knowledge bases for internal or external use."
    },
    {
      name: "Real-time Language Translation",
      desc: "Translate spoken or written communication during international meetings."
    },
    {
      name: "Social Media Management",
      desc: "Schedule posts, engage with followers, and analyze social media data."
    },
    {
      name: "Education and Training",
      desc: "Create interactive e-learning content and answer student queries."
    },
    {
        name: "Chatbots",
        desc: "Create chatbots for customer support, sales, and marketing."
    },
];

// GetAI Text Template Module
export function createGenAITextTemplate(guid: string, body: string) {
    localStorage.setItem(guid, body);
}

export function getGenAITextTemplate(guid: string) {
    localStorage.getItem(guid);
}

export async function createChatCompletion(accessToken: String, prompt: { prompt: string; provider: string; model: string; temperature: number; }, successCallback: { (userData: any): void; (arg0: any): void; }, errorCallback: { (error: any): void; (arg0: any): void; }) {
    await fetch(process.env.REACT_APP_API_BASE_URI + '/completion', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(prompt)
    }).then(response => {
        return response.json();
    }).then(completionResponse => {
        successCallback(completionResponse);
    }).catch(e => {
        errorCallback(e)
    });
}

const generateRandomGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

const generateRandomTimestamp = () => {
    const currentDate = new Date(); // Current date and time
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    const startDate = oneYearAgo;
    const endDate = currentDate;

    const randomTimestamp = new Date(
        startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime())
    );
    return randomTimestamp;
};


const generatePolicyViolationType = () => {
    const policyViolationTypes = [
        'IP Policy',
        'PII Policy',
        'Medical Information',
        'Employment Information'
    ];
    return policyViolationTypes[Math.floor(Math.random() * policyViolationTypes.length)];
};

const generateUseCase = () => {
    return workplaceApplications[Math.floor(Math.random() * workplaceApplications.length)];
};

export const generateMockChatHistoryRecords = (count: number): ChatHistoryRecord[] => {
    const mockChatHistory: ChatHistoryRecord[] = [];

    for (let i = 0; i < count; i++) {
        const randomModel = models[Math.floor(Math.random() * models.length)];
        const policyViolation = Math.random() < 0.2; // Simulating policy violation with 20% probability

        const record: ChatHistoryRecord = {
            createdBy: generateRandomGuid(),
            createdAt: generateRandomTimestamp(),
            chatRequest: loremIpsum({ count: Math.ceil(Math.random() * 3), units: 'sentences' }), 
            chatResponse: loremIpsum({ count: Math.ceil(Math.random() * 3), units: 'paragraphs' }),
            tokenCount: Math.floor(Math.random() * 100), // Adjust as needed
            policyViolation: policyViolation,
            policyViolationType: policyViolation ? generatePolicyViolationType() : '',
            model: randomModel,
            useCase: generateUseCase()
        };

        mockChatHistory.push(record);
    }

    return mockChatHistory;
};

export const getMonthYearArray = (startDate: Date, monthsCount: number): string[] => {
    const monthYearArray: string[] = [];
    const currentDate = new Date(startDate);
  
    for (let i = 0; i < monthsCount; i++) {
      const month = currentDate.toLocaleString('default', { month: 'short' });
      const year = currentDate.getFullYear();
      monthYearArray.push(`${month}/${year}`);
      
      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() - 1);
    }
  
    return monthYearArray;
};

export function getChartData(chatHistory: ChatHistoryRecord[]): any[] {
    const yearAgo = getMonthYearArray(new Date(), 13);
    const modelMonthCounts: any[] = [];

    // Initialize model month counts
    models.forEach((model: string) => {
        modelMonthCounts.push({
            model: model,
            months: yearAgo.reduce((acc: any, monthYear: string) => {
                acc[monthYear] = 0;
                return acc;
            }, {}),
            yearChange: 0,
            monthChange: 0,
            sixMonthChange: 0,
            total: 0
        });
    });

    // Count the number of chat requests per month
    chatHistory.forEach((record) => {
        const model = record.model;
        const month = new Date(record.createdAt).toLocaleString('default', { month: 'short' }) + '/' + new Date(record.createdAt).getFullYear();
        modelMonthCounts.forEach((modelMonthCount: any) => {
            if (modelMonthCount.model === model) {
                modelMonthCount.months[month]++;
                modelMonthCount.total++;
            }
        }, {});
    });

    // Calculate the percent change for each month
    modelMonthCounts.forEach((modelMonthCount: any) => {
        const months = Object.keys(modelMonthCount.months);
        const currentMonth = months[0];
        const previousMonth = months[1];
        const sixMonthsAgo = months[6];
        const yearAgo = months[12];
        // percent change and rounded up to 2 decimal places
        modelMonthCount.monthChange = Math.round(((modelMonthCount.months[currentMonth] - modelMonthCount.months[previousMonth]) / modelMonthCount.months[previousMonth]) * 100 * 100) / 100;
        modelMonthCount.sixMonthChange = Math.round(((modelMonthCount.months[currentMonth] - modelMonthCount.months[sixMonthsAgo]) / modelMonthCount.months[sixMonthsAgo]) * 100 * 100) / 100;
        modelMonthCount.yearChange = Math.round(((modelMonthCount.months[currentMonth] - modelMonthCount.months[yearAgo]) / modelMonthCount.months[yearAgo]) * 100 * 100) / 100;
    });
  
    return modelMonthCounts;
};
  
export function getPolicyViolationData(chatHistory: ChatHistoryRecord[]): any {

    const policyViolationData = {
        policyViolations: 0,
        months: 
            getMonthYearArray(new Date(), 13).reduce((acc: any, monthYear: string) => {
                acc[monthYear] = 0;
                return acc;
            }
            , {}),
        monthPercentChange: 0,
        sixMonthPercentChange: 0,
        yearPercentChange: 0
    };

    chatHistory.forEach((record) => {
        if (record.policyViolation) {
            policyViolationData.policyViolations++;
            const month = new Date(record.createdAt).toLocaleString('default', { month: 'short' }) + '/' + new Date(record.createdAt).getFullYear();
            policyViolationData.months[month]++;
        }
    });

    const months = Object.keys(policyViolationData.months);
    const currentMonth = months[0];
    const previousMonth = months[1];
    const sixMonthsAgo = months[6];
    const yearAgo = months[12];
    // percent change and rounded up to 2 decimal places
    policyViolationData.monthPercentChange = Math.round(((policyViolationData.months[currentMonth] - policyViolationData.months[previousMonth]) / policyViolationData.months[previousMonth]) * 100 * 100) / 100;
    policyViolationData.sixMonthPercentChange = Math.round(((policyViolationData.months[currentMonth] - policyViolationData.months[sixMonthsAgo]) / policyViolationData.months[sixMonthsAgo]) * 100 * 100) / 100;
    policyViolationData.yearPercentChange = Math.round(((policyViolationData.months[currentMonth] - policyViolationData.months[yearAgo]) / policyViolationData.months[yearAgo]) * 100 * 100) / 100;

    return policyViolationData;
};

export function getUseCaseData(chatHistory: ChatHistoryRecord[]): any[] {
    
    const useCaseData: any[] = [];

    // Initialize use case data
    workplaceApplications.forEach((useCase: any) => {
        useCaseData.push({
            useCase: useCase.name,
            desc: useCase.desc,
            count: 0
        });
    });

    chatHistory.forEach((record) => {
        const useCase = record.useCase.name;
        useCaseData.forEach((useCaseRecord: any) => {
            if (useCaseRecord.useCase === useCase) {
                useCaseRecord.count++;
            }
        }, {});
    });

    // sort by count
    useCaseData.sort((a, b) => (a.count < b.count) ? 1 : -1);

    return useCaseData;
};