import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import React from "react";

import {
    Card as MuiCard,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableHead as MuiTableHead,
    TableRow as MuiTableRow,
    IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import useData from "../../hooks/useData";
import CloseIcon from '@mui/icons-material/Close';

import { ThemeProps } from "../../types/theme";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
    height: 42px;
`;

const SelectableTableRow = styled(MuiTableRow)`
    height: 42px;
    cursor: pointer;

    &:hover {
        // blue
        background-color: #2196f3;
    }
`;


const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const TableHead = styled(MuiTableHead)`
    background-color: #1B2635;
    position: sticky;
    top: 0;
    z-index: 1;
`;

interface ChatLogProps {
    theme: ThemeProps;
    useCase: any;
    setUseCase: any;
    setChat: any;
    setModalOpen: any;
}

const ChatLog: React.FC<ChatLogProps> = ({ theme, useCase, setUseCase, setChat, setModalOpen }) => {
    const { chatHistoryData } = useData();
    const [chatHistoryFiltered, setChatHistoryFiltered] = React.useState(
        chatHistoryData.filter((chatHistoryRecord) => chatHistoryRecord.useCase === useCase)
    );

    const handleModalOpen = (chatHistoryRecord: any) => {
        setChat(chatHistoryRecord);
        setModalOpen(true);
    };

    React.useEffect(() => {
        let newChatHistory = chatHistoryData.filter((chatHistoryRecord) => chatHistoryRecord.useCase.name === useCase)
        newChatHistory.sort((a, b) => {
            if (a.createdAt < b.createdAt) {
                return 1;
            }
            if (a.createdAt > b.createdAt) {
                return -1;
            }
            return 0;
        });
        setChatHistoryFiltered(newChatHistory);
    }
    , [useCase, chatHistoryData, setChatHistoryFiltered]);

    return (
        <Card mb={6}>
            <CardHeader
                  action={
                    <IconButton 
                        aria-label="settings" 
                        size="large" 
                        onClick={() => setUseCase('')}
                    >
                        <CloseIcon />
                    </IconButton>
                  }
                title={`Use Case: ${useCase}`}
            />
            <CardContent>
                <div style={{ 
                    height: '430px', 
                    overflowY: 'auto', 
                    overflowX: 'hidden',
                    scrollbarWidth: 'thin',
                    paddingRight: '20px'
                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Date
                                </TableCell>
                                <TableCell>
                                    User
                                </TableCell>
                                <TableCell align="right">
                                    Model
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {chatHistoryFiltered.map((chatHistoryRecord) => (
                                <SelectableTableRow 
                                    onClick={() => handleModalOpen(chatHistoryRecord)}
                                >
                                    <TableCell>
                                        {chatHistoryRecord.createdAt.toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        {chatHistoryRecord.createdBy}
                                    </TableCell>
                                    <TableCell align="right">
                                        {chatHistoryRecord.model}
                                    </TableCell>
                                </SelectableTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </CardContent>
        </Card>      
    );
};

export default withTheme(ChatLog);