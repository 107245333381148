import { SidebarItemsType } from "../../types/sidebar";

import {
  Compass,
  Lock,
  Monitor,
  Shield,
  MessageSquare,
  Sun,
  UserCheck,
  Users,
  Briefcase,
  Copy,
  Table
} from "react-feather";
import { ScienceOutlined } from "@mui/icons-material";

const dashboardSection = [
  {
    href: "/dashboard",
    icon: Monitor,
    title: "Dashboard",
  },
] as SidebarItemsType[];

const strategySection = [
  {
    href: "/strategy",
    icon: Compass,
    title: "Strategy",
  },
  {
    href: "/ideas",
    icon: Sun,
    title: "Ideas",
  },
  {
    href: "/vendors",
    icon: Briefcase,
    title: "Vendors",
  },
] as SidebarItemsType[];

const managementSection = [
  {
    href: "/genai-text-template",
    icon: Copy,
    title: "GenAI Text Templates",
  },
  {
    href: "/ai-chat-evaluation",
    icon: ScienceOutlined,
    title: "GenAI Chat Evaluation",
  },
  {
    href: "/policies",
    icon: Shield,
    title: "AI Policies",
  },
  {
    href: "/permissions-controls",
    icon: UserCheck,
    title: "Permissions & Controls",
  },
  {
    href: "/tables",
    icon: Lock,
    title: "IP & Data Protection",
  },
  {
    href: "/chat-logs",
    icon: Table,
    title: "Chat Logs",
  },
  {
    href: "/employee-insight",
    icon: Users,
    title: "Employee Insight",
  }
] as SidebarItemsType[];

const workspaceSection = [
  {
    href: "/ai-workspace",
    icon: MessageSquare,
    title: "GenAI Chat Workspace",
  },
  {
    href: "/genai-text-template-workspace",
    icon: Copy,
    title: "GenAI Text Templates",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "",
    pages: dashboardSection,
  },
  {
    title: "AI Strategy",
    pages: strategySection,
  },
  {
    title: "AI Management",
    pages: managementSection,
  },
  {
    title: "AI Workspace",
    pages: workspaceSection,
  },
];

export default navItems;
