import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import React from "react";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableSortLabel,
  TableRow as MuiTableRow,
} from "@mui/material";
import { spacing } from "@mui/system";
import useData from "../../hooks/useData";

import { ThemeProps } from "../../types/theme";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
    padding-top: 0;
    padding-bottom: 0;
`;

const SelectableTableCell = styled(MuiTableCell)`
    padding-top: 0;
    padding-bottom: 0;  
    cursor: pointer;

    &:hover {
        color: #2196f3;
      }
`;

const TableHead = styled(MuiTableHead)`
    background-color: #1B2635;
    position: sticky;
    top: 0;
    z-index: 1;
`;


interface UseCaseListProps {
    theme: ThemeProps;
    setUseCase: any;
}

const UseCaseList: React.FC<UseCaseListProps> = ({ theme, setUseCase }) => {
    const { useCaseData } = useData();
    const [useCaseDataSorted, setUseCaseDataSorted] = React.useState<{ useCase: string; count: number; desc: string }[]>(useCaseData);
    const [orderBy, setOrderBy] = React.useState<string>('');
    const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
    const colors = [
        // unique colors
        "#FF6633", "#FFB399", "#FF33FF", "#FFFF99", "#00B3E6",
        "#E6B333", "#3366E6", "#999966", "#99FF99", "#B34D4D",
        "#80B300", "#809900", "#E6B3B3", "#6680B3", "#66991A",
        "#FF99E6", "#CCFF1A", "#FF1A66", "#E6331A", "#33FFCC",
        "#66994D", "#B366CC", "#4D8000", "#B33300", "#CC80CC",
    ];

    const sortingFunction = (orderBy: string, order: string) => {
        return (a: any, b: any) => {
          if (order === 'asc') {
            return a[orderBy] > b[orderBy] ? 1 : -1;
          } else {
            return a[orderBy] < b[orderBy] ? 1 : -1;
          }
        };
    };
      

    const handleSortClick = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sortedData;
        if (property === 'useCase') {
            sortedData = useCaseData.slice().sort(sortingFunction(property, isAsc ? 'desc' : 'asc'));
        }
        else {
            sortedData = useCaseData.sort(sortingFunction(property, isAsc ? 'desc' : 'asc'));
        }
        setUseCaseDataSorted(sortedData);
    };


    

    return (
        <Card mb={6}>
        <CardHeader
            title="Employee Use Cases"
        />

        <CardContent>
            <div style={{ 
                height: '430px', 
                overflowY: 'auto', 
                overflowX: 'hidden',
                scrollbarWidth: 'thin',
                paddingRight: '20px'
                }}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell align="center">
                        <TableSortLabel
                            active={orderBy === 'useCase'}
                            direction={orderBy === 'useCase' ? order : 'asc'}
                            onClick={() => handleSortClick('useCase')}
                        >
                            AI Use Case
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'count'}
                            direction={orderBy === 'count' ? order : 'asc'}
                            onClick={() => handleSortClick('count')}
                        >
                            Total Chats
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                        Description
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {useCaseDataSorted.map((useCase: any, index: number) => (
                    <TableRow key={useCase.useCase}>
                        <SelectableTableCell
                            onClick={() => setUseCase(useCase.useCase)}
                            style={{ cursor: 'pointer' }}
                        >
                        <div
                        style={{
                            width: "5px",
                            height: "5px",
                            marginRight: "5px",
                            backgroundColor: colors[index],
                            borderRadius: "50%",
                            display: "inline-block",
                            marginBottom: "2px",
                        }}></div>
                        {useCase.useCase}
                        </SelectableTableCell>
                        <TableCell align="left">{useCase.count}</TableCell>
                        <TableCell align="left">{useCase.desc}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </div>
        </CardContent>
        </Card>
    );
};

export default withTheme(UseCaseList);
