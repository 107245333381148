import React from "react";
import { Helmet } from "react-helmet-async";
import { Search, UserCheck } from "react-feather"; import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid, InputAdornment, InputLabel, OutlinedInput, Switch, Typography,
} from "@mui/material";

function PermissionsControls() {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid md={10} item>
          <h1><UserCheck style={{ position: 'relative', top: '2px', marginRight: '15px' }} />Permissions & Controls</h1>
        </Grid>
      </Grid>
      <Grid justifyContent="space-between" container spacing={6} sx={{ mb: 2 }}>
        <Grid md={10} sx={{ mt: 4, ml: 10 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-amount">Search people and groups</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              endAdornment={<InputAdornment position="end"><Search /></InputAdornment>}
              label="Search people and groups"
            />
          </FormControl>
        </Grid>

        <Grid md={10} sx={{ mt: 4, ml: 10 }}>
          <h3>Direct Marketing Team (23 members)</h3>
        </Grid>

        <Grid md={10} sx={{ ml: 8 }} item>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ ml: 1 }}><b>GenAI Content Restrictions</b></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 40px' }}>
              <div>
                <Grid container sx={{ mb: 6 }}>
                  <Grid xs={12} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Enforce the following content restrictions on GenAI prompts:</div>
                    <FormGroup>
                      <FormControlLabel control={<Switch defaultChecked />} label="Medical and healthcare advice not allowed" />
                      <FormControlLabel control={<Switch defaultChecked />} label="AI cannot be used for recruiting and hiring" />
                      <FormControlLabel control={<Switch defaultChecked />} label="AI cannont be used for financial or investment advice" />
                      <FormControlLabel control={<Switch defaultChecked />} label="Block personally identifiable information (PII)" />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>

        </Grid>
      </Grid >

      <Grid justifyContent="space-between" container spacing={6} sx={{ mb: 2 }}>
        <Grid md={10} sx={{ ml: 8 }} item>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ ml: 1 }}><b>AI Vendor/Model Permissions</b></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 40px' }}>
              <div>
                <Grid container sx={{ mb: 6 }}>
                  <Grid xs={12} sx={{ mb: 6 }}>
                    <div style={{ textTransform: 'uppercase', fontSize: '0.8em', color: "#bbb", marginBottom: '3px' }}>Text Generation Vendors/Models:</div>
                    <FormGroup>
                      <FormControlLabel control={<Switch defaultChecked />} label="OpenAI: ChatGPT 3.5" />
                      <FormControlLabel control={<Switch defaultChecked={false} />} label="OpenAI: ChatGPT 4" />
                      <FormControlLabel control={<Switch defaultChecked />} label="Google: Bard" />
                      <FormControlLabel control={<Switch defaultChecked={false} />} label="OSS (internal hosting): StableLM" />
                      <FormControlLabel control={<Switch defaultChecked={false} />} label="OSS (external hosting): Falcon 40B" />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid >

      <Grid justifyContent="space-between" container spacing={6} sx={{ mb: 2 }}>
        <Grid md={10} sx={{ ml: 8 }} item>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ ml: 1 }}><b>Usage & Cost Controls</b></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 40px' }}>

              <Grid container spacing={6} sx={{ mt: 8, mb: 2 }}>
                <Grid xs={3}>
                  <div style={{ position: 'relative', top: '20px' }}>
                    Daily Spending Limit:
                  </div>
                </Grid>
                <Grid xs={2}>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Daily Limit</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      label="Daily Limit"
                      defaultValue={'$100.00'}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ mt: 8, mb: 2 }}>
                <Grid xs={3}>
                  <div style={{ position: 'relative', top: '20px' }}>
                    Weekly Spending Limit:
                  </div>
                </Grid>
                <Grid xs={2}>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Weekly Limit</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      label="Weekly Limit"
                      defaultValue={'$250.00'}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ mt: 8, mb: 2 }}>
                <Grid xs={3}>
                  <div style={{ position: 'relative', top: '20px' }}>
                    Monthly Spending Limit:
                  </div>
                </Grid>
                <Grid xs={2}>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Monthly Limit</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      label="Monthly Limit"
                      defaultValue={'$500.00'}
                    />
                  </FormControl>
                </Grid>
              </Grid>

            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid >

    </React.Fragment >
  );
}

export default PermissionsControls;
