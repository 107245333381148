import { useState } from "react";
import axios from 'axios';
import OnboardingModal from "../../components/OnboardingModal";
import BiotechIcon from '@mui/icons-material/Biotech';
import ConstructionIcon from '@mui/icons-material/Construction';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SchoolIcon from '@mui/icons-material/School';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import PaymentIcon from '@mui/icons-material/Payment';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import FactoryIcon from '@mui/icons-material/Factory';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AltRouteIcon from '@mui/icons-material/AltRoute';

function Onboarding() {
    const pages = [
    {
      options: [
        'Increase revenue',
        'Automate administrative tasks',
        'Do something',
        'Something else',
        'Improve customer service',
        'Improve internal communications'
      ],
      mainTitle: "Welcome, let's get started. What goals do you have for using AI in your business?",
      subTitle: "Don't worry, you can always update these later if your needs change.",
      optionType: "goals",
      propgressComplete: 5,
    },
    {
      options: [
        "Biotechnology",
        "Construction",
        "Consumer Goods",
        "Education",
        "Energy",
        "Financial Services",
        "Healthcare",
        "Manufacturing",
        "Professional Services",
        "Real Estate",
        "Retail",
        "Technology",
        "Transportation and Logistics",
        "Other"
      ],
      icons: [
        <BiotechIcon />,
        <ConstructionIcon />,
        <ShoppingBasketIcon />,
        <SchoolIcon />,
        <ElectricBoltIcon />,
        <PaymentIcon />,
        <HealthAndSafetyIcon />,
        <FactoryIcon />,
        <CoPresentIcon />,
        <HolidayVillageIcon />,
        <StorefrontIcon />,
        <DeveloperBoardIcon />,
        <LocalShippingIcon />,
        <AltRouteIcon />
      ],
      mainTitle: "Which industry best describes your business?",
      subTitle: "",
      optionType: "industry",
      propgressComplete: 25
    }
  ];

  //States
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [finalOptions, setFinalOptions] = useState<{ [key: string]: string[] | undefined }>({});
  const [optionChecked, setOptionChecked] = useState<{ [key: string]: boolean }>(
    pages[pageNumber].options.reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {} as { [key: string]: boolean })
  );

  // useEffect(() => {
  //   console.log(finalOptions)
  // }, [finalOptions]);

  const handleIndustry = (industry: string) => {
    //can only select one industry and unselect the previous one
    const newOptions = { ...optionChecked };
    //unselect the previous industry
    Object.keys(newOptions).forEach((key) => {
      newOptions[key] = false;
    });
    //select the new industry
    newOptions[industry] = true;
    setOptionChecked(newOptions);
  };

  const submitOptions = () => {
    const requestData = {
      email: 'user@example.com',
      password: 'securepassword',
      firstName: 'John',
      lastName: 'Doe',
      options: finalOptions,
    }
    axios.post('/api/auth/sign-up', requestData)
    .then(response => {
      // Handle the response here
      console.log('Response:', response.data);
    })
    .catch(error => {
      // Handle any errors here
      console.error('Error:', error);
  });
  }


  //Set the option to true or false
  const saveOption = (option: string) => {
    if(pages[pageNumber].optionType === 'industry') {
      handleIndustry(option);
      return;
    }
    const newOptions = { ...optionChecked };
    newOptions[option] = !newOptions[option];
    setOptionChecked(newOptions);
  };

  //Go to the next page
  const nextButton = () => {
    const newOptions = { ...finalOptions };
    newOptions[pages[pageNumber].optionType] = Object.keys(optionChecked).filter((key) => optionChecked[key]);
    setFinalOptions(newOptions);

    if (pageNumber < pages.length - 1) {
      setOptionChecked(
        pages[pageNumber + 1].options.reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {} as { [key: string]: boolean })
      );
      setPageNumber(pageNumber + 1);
    } else {
      submitOptions();
    }
  };

  const backButton = () => {
    if (pageNumber === 0) {
      return;
    }
    
    const previousOptions = finalOptions[pages[pageNumber - 1].optionType];
  
    if (previousOptions) {
      const newOptionChecked = pages[pageNumber - 1].options.reduce((acc, key) => {
        acc[key] = previousOptions.includes(key); // Set to true if the option is in previousOptions
        return acc;
      }, {} as { [key: string]: boolean });
      
      setOptionChecked(newOptionChecked);
    }

    const newOptions = { ...finalOptions };
    delete newOptions[pages[pageNumber].optionType];
    setFinalOptions(newOptions);
    setPageNumber(pageNumber - 1);
  };
  
  

  return (
    <OnboardingModal
      mainTitle={pages[pageNumber].mainTitle}
      subTitle={pages[pageNumber].subTitle}
      propgressComplete={pages[pageNumber].propgressComplete}
      icons={pages[pageNumber].icons}
      nextButtonHandler={nextButton}
      backButtonHandler={backButton}
      optionChecked={optionChecked}
      saveOption={(option) => saveOption(option)}
    />
  );
}

export default Onboarding;
