import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { CheckCircle, Clipboard, Copy, Layers, Lock, Send } from "react-feather";
import styled from "@emotion/styled";
import {
  Accordion,
  AccordionSummary,
  Button,
  Card as MuiCard,
  CardActionArea,
  CardContent,
  Grid, TextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Card = styled(MuiCard)(spacing);

function GenAITextTemplateWorkspace() {

  function delay(milliseconds: number) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  const [promptSectionExpanded, setPromptSectionExpanded] = useState(true);

  const openPromptSection = () => {
    if (promptSectionExpanded === false) {
      const sendPromptButton1 = document.getElementById('send-prompt-button-1');
      const sendPromptButton2 = document.getElementById('send-prompt-button-2');
      const sendPromptButton3 = document.getElementById('send-prompt-button-3');
      const sendPromptButton4 = document.getElementById('send-prompt-button-4');

      if (sendPromptButton1 && sendPromptButton2 && sendPromptButton3 && sendPromptButton4) {
        sendPromptButton1.style.display = "inline-flex";
        sendPromptButton2.style.display = "none";
        sendPromptButton3.style.display = "none";
        sendPromptButton4.style.display = "none";
      }

      setPromptSectionExpanded(true);
    }
  }

  const sendPrompt = async () => {

    const sendPromptButton1 = document.getElementById('send-prompt-button-1');
    const sendPromptButton2 = document.getElementById('send-prompt-button-2');
    const sendPromptButton3 = document.getElementById('send-prompt-button-3');
    const sendPromptButton4 = document.getElementById('send-prompt-button-4');
    const promptTextField = document.getElementById('prompt-text-field');
    const chatResponses = document.getElementById('chat-responses');

    if (sendPromptButton1 && sendPromptButton2 && sendPromptButton3 && sendPromptButton4 && promptTextField) {
      sendPromptButton1.style.display = "none";
      sendPromptButton2.style.display = "inline-flex";
      await delay(1000);
      sendPromptButton2.style.display = "none";
      sendPromptButton3.style.display = "inline-flex";
      await delay(1500);
      sendPromptButton3.style.display = "none";
      sendPromptButton4.style.display = "inline-flex";
      await delay(1500);
      setPromptSectionExpanded(false);
      if (chatResponses) {
        chatResponses.style.display = 'initial';
      }

      const chatgpt4Window = document.getElementById('chatgpt4-window');

      const chatgpt4Text = `
      <h3>Title: Development of a Data Pipeline for Log Data Ingestion into Databricks Workspace</h3>

      <p><strong>As</strong> a data engineer,<br>
      <strong>I want</strong> to create a data pipeline that ingests log data into a Databricks workspace on AWS,<br>
      <strong>So that</strong> I can perform data engineering and transformations efficiently.</p>
  
      <h3>Acceptance Criteria:</h3>
      <ol>
          <li>The data pipeline should fetch log data from designated AWS S3 buckets and ingest it into the Databricks workspace.</li>
          <li>The CI/CD pipeline, possibly using AWS CodePipeline, should be configured to trigger the data pipeline upon any changes or at scheduled intervals.</li>
          <li>All data transmissions between S3 and Databricks should utilize TLS 1.2 or higher for encryption in transit.</li>
          <li>Data stored within the Databricks workspace should leverage AWS KMS for encryption at rest.</li>
          <li>Security tests, using tools like AWS Inspector, should be run to validate the encryption mechanisms and ensure no data vulnerabilities exist.</li>
          <li>A comprehensive documentation should detail the data pipeline's flow, covering data sources, transformation logic, and destination schemas within Databricks.</li>
      </ol>
      `;

      const chatgpt4Array = chatgpt4Text.split(" ");
      let chatgpt4InProgress = "";

      for (let i = 0; i < chatgpt4Array.length; i++) {
        await delay(50)
        if (chatgpt4Window) {
          chatgpt4InProgress += ((chatgpt4Array[i] + " "))
          chatgpt4Window.innerHTML = chatgpt4InProgress
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container>
        <Grid md={12} item>
          <div style={{ fontSize: '2em', fontWeight: 'bold', marginTop: '-40px', marginBottom: '20px' }}>
            <Copy style={{ position: 'relative', top: '2px', marginRight: '15px' }} />
            GenAI Text Templates Workspace
          </div>
        </Grid>
      </Grid>

      <div id="chat-main-window">

        <Grid justifyContent="space-between" container sx={{ mb: 4 }}>
          <Grid md={12} item>
            <Accordion id="prompt-section" expanded={promptSectionExpanded} onClick={openPromptSection}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ fontSize: '1.5em', fontWeight: '700', marginLeft: '20px', position: 'relative', top: '10px' }}>
                  Template: Product Backlog User Story
                </div>

              </AccordionSummary>

              <div style={{ padding: '10px 30px' }}>

                <div style={{ marginBottom: '20px' }}>
                  This template creates a product backlog user story for agile development teams.
                </div>

                <Card mb={10} mr={10}>
                  <CardActionArea>
                    <CardContent>
                      <Grid container sx={{ mb: 6 }}>
                        <Grid xs={12} item={true}>
                          <TextField
                            id="prompt-text-field"
                            label="Story purpose"
                            placeholder="Describe what this story should accomplish"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid container sx={{ mb: 6 }}>
                        <Grid xs={12} item={true}>
                          <div>
                            Include the following in the acceptance criteria:
                          </div>

                          <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                            <input type="checkbox" /> Documentation
                          </div>

                          <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                            <input type="checkbox" /> CI/CD
                          </div>

                          <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                            <input type="checkbox" /> Unit Tests
                          </div>

                          <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                            <input type="checkbox" /> User Acceptance Testing
                          </div>

                          <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                            <input type="checkbox" /> Security Testing
                          </div>

                        </Grid>
                      </Grid>

                      <Grid container sx={{ mb: 6 }}>
                        <Grid xs={12} item={true}>
                          <div style={{
                            textTransform: 'uppercase',
                            fontSize: '0.8em',
                            color: "#bbb",
                            marginBottom: '12px',
                            marginTop: '40px',
                            width: '100%',
                            textAlign: 'center'
                          }}>
                            <Button id="send-prompt-button-1" onClick={sendPrompt} style={{ width: '250px', height: '44px' }} variant="contained" color="success" endIcon={<Send />} >Generate Text</Button>
                            <Button id="send-prompt-button-2" style={{ width: '250px', height: '44px', display: 'none' }} variant="contained" color="success" endIcon={<Lock />} >Checking for Protected Data...</Button>
                            <Button id="send-prompt-button-3" style={{ width: '250px', height: '44px', display: 'none' }} variant="contained" color="success" endIcon={<Layers />} >Optimizing Prompt...</Button>
                            <Button id="send-prompt-button-4" style={{ width: '250px', height: '44px', display: 'none' }} variant="contained" color="success" endIcon={<CheckCircle />} >Done</Button>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

            </Accordion>

          </Grid>
        </Grid >
      </div>

      <div id="chat-responses" style={{ display: 'none' }}>
        <Grid container>
          <Grid xs={2} item={true}>
            &nbsp;
          </Grid>
          <Grid xs={8} item={true}>
            <Card sx={{ mb: 6, ml: 6, mr: 3 }}>
              <CardContent>
                <div id="chatgpt4-window" style={{ border: 'solid 1px #888', margin: '14px 10px', padding: '15px 20px', borderRadius: '8px', minHeight: '600px' }}>
                </div>
              </CardContent>

              <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px' }}>
                <Button id="copy-response" style={{ width: '250px', height: '44px' }} variant="contained" color="success" endIcon={<Clipboard />} >Copy to Clipboard</Button>
              </div>

            </Card>
          </Grid>
          <Grid xs={2} item={true}>
            &nbsp;
          </Grid>

        </Grid>

      </div>
    </React.Fragment >
  );
}

export default GenAITextTemplateWorkspace;
