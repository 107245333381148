import "react-app-polyfill/stable";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';

import "chart.js/auto";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

// Note: Remove the following line if you want to disable the API mocks.
import "./mocks";

import { ThemeProvider } from "./contexts/ThemeContext";
import { DataProvider } from "./contexts/DataContext";
import { Auth0Provider } from '@auth0/auth0-react';

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <>
    <BrowserRouter>
      <ThemeProvider>
        <DataProvider>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
            authorizationParams={{
              redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI
            }}
          >
            <App />
          </Auth0Provider>
        </DataProvider>
      </ThemeProvider>
    </BrowserRouter>
  </>
);

reportWebVitals();
