import React from "react";
import { Helmet } from "react-helmet-async";
import { Users } from "react-feather"; 
import {
    Grid
} from "@mui/material";
import UseCaseList from "./UseCaseList";
import ChatModal from "./ChatModal";
import ChatLog from "./ChatLog";
import useTheme from "../../hooks/useTheme";


function EmployeeInsight() {
  const theme = useTheme();
  const [useCase, setUseCase] = React.useState<string>('');
  const [Chat, setChat] = React.useState<string>('');
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid md={10} item>
          <h1><Users style={{ position: 'relative', top: '2px', marginRight: '15px' }} />Employee Insight</h1>
        </Grid>
        <Grid md={6} item>
          <UseCaseList theme={theme} setUseCase={setUseCase}/>
        </Grid>
        <Grid md={6} item>
          <ChatLog theme={theme} useCase={useCase} setUseCase={setUseCase} setChat={setChat} setModalOpen={setModalOpen}/>
        </Grid>
      </Grid>
      <ChatModal theme={theme} Chat={Chat} modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </React.Fragment >
  );
}

export default EmployeeInsight;
