import React from "react";
import { generateMockChatHistoryRecords, getChartData, getPolicyViolationData, getUseCaseData } from "../data";
import { ChatHistoryRecord } from "../types/chatHistoryRecord";

const mockData = generateMockChatHistoryRecords(500)

const initialState = {
    chatHistoryData: mockData,
    chartData: getChartData(mockData),
    violationData: getPolicyViolationData(mockData),
    useCaseData: getUseCaseData(mockData),
    setChatHistoryDataAndChartData: (data: ChatHistoryRecord[]) => {}
}

const DataContext = React.createContext(initialState);

type DataProviderProps = {
    children: React.ReactNode;
};

function DataProvider({ children }: DataProviderProps) {
    const [chatHistoryData, _setChatHistoryData] = React.useState(initialState.chatHistoryData);
    const [chartData, _setChartData] = React.useState(initialState.chartData);
    const [violationData, _setViolationData] = React.useState(initialState.violationData);
    const [useCaseData, _setUseCaseData] = React.useState(initialState.useCaseData);

    const setChatHistoryDataAndChartData = (data: ChatHistoryRecord[]) => {
        _setChatHistoryData(data);
        _setChartData(getChartData(data));
        _setViolationData(getPolicyViolationData(data));
        _setUseCaseData(getUseCaseData(data));
    }

    return (
        <DataContext.Provider value={{chatHistoryData, chartData, violationData, useCaseData, setChatHistoryDataAndChartData}}>
            {children}
        </DataContext.Provider>
    );
}

export { DataProvider, DataContext };
