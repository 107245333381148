import React from "react";
import styled from "@emotion/styled";

import { Button as MuiButton } from "@mui/material";
import { Loop as LoopIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { generateMockChatHistoryRecords } from "../../data";
import useData from "../../hooks/useData";

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

function Actions() {

  const { setChatHistoryDataAndChartData } = useData();

  const refresh = () => {
      const mockData = generateMockChatHistoryRecords(500); // change later
      setChatHistoryDataAndChartData(mockData);
  }

  return (
    <React.Fragment>
      <SmallButton size="small" mr={2} onClick={refresh}>
        <LoopIcon/>
      </SmallButton>
    </React.Fragment>
  );
}

export default Actions;
